import React from "react";
import brand2 from "../../assets/brand-logo/giffgaff_logo.png";
import brand3 from "../../assets/brand-logo/gkt_logo.png";
import brand5 from "../../assets/brand-logo/O2_logo.png";
import brand4 from "../../assets/brand-logo/talkMobile_logo.png";
import brand1 from "../../assets/brand-logo/three_logo.png";
import brand6 from "../../assets/brand-logo/vodafone_logo.png";

const OrderCard = ({ data, brandIndex }) => {
    const brands = [
        {
            id: 8,
            img: brand2,
        },
        {
          id: 1,
          img: brand1,
        },
        {
          id: 2,
          img: brand3,
        },
        {
          id: 3,
          img: brand4,
        },
        {
          id: 4,
          img: brand5,
        },
        {
          id: 5,
          img: brand6,
        },
        {
          id: 6,
          img: brand2,
        },
        {
          id: 7,
          img: brand3,
        },
      ];

  return (
    <>
      <div className="flex flex-col border custom-border-50 rounded-lg p-4">
        <div className="grid grid-cols-3 mt-4">
          <div className="flex flex-col justify-center items-center">
            <h3 className="text-lg text-center font-semibold">{data.createdDate}</h3>
            <p>Order Date</p>
          </div>
          {/* <div className="break-line"></div> */}
          <div className="flex flex-col justify-center items-center middle-box">
            <h3 className="text-lg font-semibold">
              {data.orderNumber}
            </h3>
            <p>Order Number</p>
          </div>
          {/* <div className="break-line"></div> */}
          <div className="flex flex-col justify-center items-center">
            <h3 className="text-lg font-semibold break-all text-center">
              {data.lockMsisdn}
            </h3>
            <p>MSISDN</p>
          </div>
        </div>
        <div className="grid grid-cols-3 mt-4">
          <div className="flex flex-col justify-center items-center">
            <h3 className="text-lg font-semibold">{data.orderStatusDescription}</h3>
            <p>Order Status</p>
          </div>
          {/* <div className="break-line"></div> */}
          <div className="flex flex-col justify-center items-center middle-box">
            <h3 className="text-lg font-semibold">
              {data.orderTypeDescription}
            </h3>
            <p>Order Type</p>
          </div>
          {/* <div className="break-line"></div> */}
          <div className="flex flex-col justify-center items-center">
            <h3 className="text-lg font-semibold">
              {data.amount}
            </h3>
            <p>Amount</p>
          </div>
        </div>
        <div className="grid grid-cols-3 mt-4">
          <div className="flex flex-col justify-center items-center">
            <h3 className="text-lg font-semibold">{data.planData}</h3>
            <p>Data</p>
          </div>
          {/* <div className="break-line"></div> */}
          <div className="flex flex-col justify-center items-center middle-box">
            <h3 className="text-lg font-semibold">
              {" "}
              {data.noOfMinutes === -1 ? "Unlimited" : data.noOfMinutes}
            </h3>
            <p>Minutes</p>
          </div>
          {/* <div className="break-line"></div> */}
          <div className="flex flex-col justify-center items-center">
            <h3 className="text-lg font-semibold">
              {data.noOfText === -1 ? "Unlimited" : data.noOfText}
            </h3>
            <p>Text</p>
          </div>
        </div>
        <div className="grid grid-cols-3 mt-4">
          <div className="flex flex-col justify-center items-center">
            <img
              className="h-8 md:h-10 w-auto"
              src={brands[brandIndex].img}
              alt="network 3"
            />
            {/* <p className="font-semibold">Data</p> */}
          </div>
          <div className="flex flex-col justify-center items-center middle-box">
            <h4 className="text-md font-semibold">
              {data.contractTerm}
            </h4>
            <p>Duration</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderCard;
