import React from 'react';
// import microLoading from "../../assets/img/micro-loader.png";


const MicroLoader = () => {
  return (
    <div className='d-flex justify-content-center align-items-center micro-loader'>
        <img className='loading-img' src={""} alt="loading" />
    </div>
  )
}

export default MicroLoader