import React, { useState, useEffect } from "react";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";
import useScrollToTop from "../customHooks/useScrollToTop";
import successGif from "../../assets/success.gif";
import pendingGif from "../../assets/pending.gif";
import failureGif from "../../assets/failure.gif";
import failedGif from "../../assets/failure.gif";
import { Link } from "react-router-dom";

export default function ProductDetails() {
  useScrollToTop();

  //states
  const [orderConfirmation, setOrderConfirmation] = useState(
    JSON.parse(localStorage.getItem("orderConfirmation"))
      ? JSON.parse(localStorage.getItem("orderConfirmation"))
      : null
  );
  const [userRoleId, setUserRoleId] = useState(localStorage.getItem("roleId"));

  const data = [
    {
      id: 1,
      key: "Data",
      value: "70 GB",
    },
    {
      id: 2,
      key: "Minutes",
      value: "Unlimited",
    },
    {
      id: 3,
      key: "Text",
      value: "Unlimited",
    },
    {
      id: 4,
      key: "Network",
      value: "Three",
    },
    {
      id: 5,
      key: "Duration",
      value: "6 Months",
    },
    {
      id: 6,
      key: "Price",
      value: "£15",
    },
  ];
  // states end

  //effects

  return (
    <>
      <Navbar />
      {orderConfirmation &&
      orderConfirmation.orderStatus.orderStatusId === 2 ? (
        <section className="mx-auto max-w-4xl px-4 sm:px-6 lg:px-8 mt-5">
          <div className="w-full text-center flex flex-col items-center mx-auto">
            <img
              className="mb-3"
              width={60}
              height={60}
              src={successGif}
              alt="Success"
            />
            <h1 className="text-2xl font-semibold tracking-tight my-4">
              Thank you for your purchase!
            </h1>
            <p className="my-2">Please find the order and plan detail below.</p>
            <div className="my-5 max-w-xl w-full">
              <div className="w-full flex justify-between items-center border-b py-2">
                <p>Order Number</p>
                <p className="font-semibold">{orderConfirmation.orderNumber}</p>
              </div>
              <div className="w-full flex justify-between items-center border-b py-2">
                <p>Order Amount</p>
                <p className="font-semibold">£{orderConfirmation.amount}</p>
              </div>
              <div className="w-full flex justify-between items-center border-b py-2">
                <p>Discount Amount</p>
                <p className="font-semibold">
                  £{orderConfirmation.discountAmount}
                </p>
              </div>
              <div className="w-full flex justify-between items-center border-b py-2">
                <p>Order Type</p>
                <p className="font-semibold">
                  {orderConfirmation.orderType.orderTypeDescription}
                </p>
              </div>
              <div className="w-full flex justify-between items-center border-b py-2">
                <p>MSISDN</p>
                <p className="font-semibold">
                  {`+${orderConfirmation.creditCheck.lockMsisdn}`}
                </p>
              </div>
              {userRoleId != null && userRoleId === "ROLE_DEALER" && (
                <>
                  <div className="w-full flex justify-between items-center border-b py-2">
                    <p>ICCID</p>
                    <p className="font-semibold">
                      {orderConfirmation.creditCheck.iccid}
                    </p>
                  </div>
                </>
              )}
              <div className="w-full flex justify-between items-center border-b py-2">
                <p>Data</p>
                <p className="font-semibold">
                  {orderConfirmation.creditCheck.plan.data === -1
                    ? "Unlimited"
                    : `${orderConfirmation.creditCheck.plan.data} GB`}            
                </p>
              </div>
              <div className="w-full flex justify-between items-center border-b py-2">
                <p>Minutes</p>
                <p className="font-semibold">
                  {orderConfirmation.creditCheck.plan.noOfMinutes === -1
                    ? "Unlimited"
                    : orderConfirmation.creditCheck.plan.noOfMinutes}
                </p>
              </div>
              <div className="w-full flex justify-between items-center border-b py-2">
                <p>Text</p>
                <p className="font-semibold">
                  {orderConfirmation.creditCheck.plan.noOfText === -1
                    ? "Unlimited"
                    : orderConfirmation.creditCheck.plan.noOfText}
                </p>
              </div>
              <div className="w-full flex justify-between items-center border-b py-2">
                <p>Network</p>
                <p className="font-semibold">
                  {orderConfirmation.creditCheck.plan.network.networkName}
                </p>
              </div>
              <div className="w-full flex justify-between items-center border-b py-2">
                <p>Duration</p>
                <p className="font-semibold">
                  {orderConfirmation.creditCheck.plan.contractTerm} Months
                </p>
              </div>
              <div className="w-full flex justify-between items-center border-b py-2">
                <p>Plan Price</p>
                <p className="font-semibold">
                  £{orderConfirmation.creditCheck.plan.finalPrice}
                </p>
              </div>
            </div>
            <Link to="/">
              <button className="cta-two rounded-md py-2 px-3 mt-4">
                Go to Homepage
              </button>
            </Link>
          </div>
        </section>
      ) : orderConfirmation &&
        orderConfirmation.orderStatus.orderStatusId === 3 ? (
        <section className="mx-auto max-w-4xl px-4 sm:px-6 lg:px-8 mt-5">
          <div className="w-full text-center flex flex-col items-center mx-auto">
            <img
              className="mb-3"
              width={60}
              height={60}
              src={failedGif}
              alt="Success"
            />
            <h1 className="text-2xl font-semibold tracking-tight my-4">
              Order Failed!
            </h1>
            <p className="my-2">Please find the order and plan detail below.</p>
            <div className="my-5 max-w-xl w-full">
              <div className="w-full flex justify-between items-center border-b py-2">
                <p>Order Number</p>
                <p className="font-semibold">{orderConfirmation.orderNumber}</p>
              </div>
              <div className="w-full flex justify-between items-center border-b py-2">
                <p>Order Amount</p>
                <p className="font-semibold">£{orderConfirmation.amount}</p>
              </div>
              <div className="w-full flex justify-between items-center border-b py-2">
                <p>Discount Amount</p>
                <p className="font-semibold">
                  £{orderConfirmation.discountAmount}
                </p>
              </div>
              <div className="w-full flex justify-between items-center border-b py-2">
                <p>Order Type</p>
                <p className="font-semibold">
                  {orderConfirmation.orderType.orderTypeDescription}
                </p>
              </div>
              <div className="w-full flex justify-between items-center border-b py-2">
                <p>MSISDN</p>
                <p className="font-semibold">
                  {`+${orderConfirmation.creditCheck.lockMsisdn}`}
                </p>
              </div>
              {userRoleId != null && userRoleId === "ROLE_DEALER" && (
                <>
                  <div className="w-full flex justify-between items-center border-b py-2">
                    <p>ICCID</p>
                    <p className="font-semibold">
                      {orderConfirmation.creditCheck.iccid}
                    </p>
                  </div>
                </>
              )}
              <div className="w-full flex justify-between items-center border-b py-2">
                <p>Data</p>
                <p className="font-semibold">
                  {orderConfirmation.creditCheck.plan.data} GB
                </p>
              </div>
              <div className="w-full flex justify-between items-center border-b py-2">
                <p>Minutes</p>
                <p className="font-semibold">
                  {orderConfirmation.creditCheck.plan.noOfMinutes === -1
                    ? "Unlimited"
                    : orderConfirmation.creditCheck.plan.noOfMinutes}
                </p>
              </div>
              <div className="w-full flex justify-between items-center border-b py-2">
                <p>Text</p>
                <p className="font-semibold">
                  {orderConfirmation.creditCheck.plan.noOfText === -1
                    ? "Unlimited"
                    : orderConfirmation.creditCheck.plan.noOfText}
                </p>
              </div>
              <div className="w-full flex justify-between items-center border-b py-2">
                <p>Network</p>
                <p className="font-semibold">
                  {orderConfirmation.creditCheck.plan.network.networkName}
                </p>
              </div>
              <div className="w-full flex justify-between items-center border-b py-2">
                <p>Duration</p>
                <p className="font-semibold">
                  {orderConfirmation.creditCheck.plan.contractTerm} Months
                </p>
              </div>
              <div className="w-full flex justify-between items-center border-b py-2">
                <p>Plan Price</p>
                <p className="font-semibold">
                  £{orderConfirmation.creditCheck.plan.finalPrice}
                </p>
              </div>
            </div>
            <Link to="/">
              <button className="cta-two rounded-md py-2 px-3 mt-4">
                Go to Homepage
              </button>
            </Link>
          </div>
        </section>
      ) : null}
      {/* <Footer /> */}
    </>
  );
}
