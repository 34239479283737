import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import logo from "../../assets/logo.png";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/solid";
import useScrollToTop from "../customHooks/useScrollToTop";
import RegisterService from "../../services/RegisterService";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup"; // Added Yup for validation
import { Formik, Field, ErrorMessage, Form } from "formik"; // Added Formik components
import { Transition } from "@headlessui/react";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import DealerSignupService from "../../services/DealerSignupService";
import { useDispatch, useSelector } from "react-redux";
import {
  closeModal,
  openModal,
  selectModalState,
} from "../modalComponent/modalSlice";
import Button from "../button/Button";
import ModalComponent from "../modalComponent/ModalComponent";
import VerificationService from "../../services/VerificationService";
import { handleLogIn } from "../navbar/navbarSlice";
import { useForm } from "react-hook-form";
import { FaPaperPlane } from "react-icons/fa6";
import { FiClock } from "react-icons/fi";
import Cookies from "js-cookie";

import Loader from "../loader/Loader";
import CustomModal from "../views/components/CustomModal";
import MicroLoader from "../loader/MicroLoader";
import LoaderButton from "../button/LoaderButton";
import { BiSolidWatch } from "react-icons/bi";

export default function Register() {
  useScrollToTop();
  const [togglePassword, setTogglePassword] = useState(false);
  const [toggleRePassword, setToggleRePassword] = useState(false);
  const [toggleRefferalCodeOne, setToggleRefferalCodeOne] = useState(false);
  const [toggleRefferalCodeTwo, setToggleRefferalCodeTwo] = useState(false);
  const [togglePasswordGroup, setTogglePasswordGroup] = useState(false);
  const [accountType, setAccountType] = useState(1);
  const [firstAcc, setFirstAcc] = useState(true);
  const [secondAcc, setSecondAcc] = useState(false);
  const [stepOne, setStepOne] = useState(true);
  const [stepTwo, setStepTwo] = useState(false);
  const [readonlyDealer, setReadonlyDealer] = useState(false);
  const {
    register: dealerVerify,
    handleSubmit: verifySubmitDealer,
    formState: { errors: errorsDealer },
  } = useForm();
  const {
    register: registerDealerValidation,
    handleSubmit: handleSubmitDealer,
    watch,
    setValue,
    getValues,
    formState: { errors: dealerError },
  } = useForm();
  const [open, setOpen] = useState(false);
  const selectOpenModal = useSelector(selectModalState);

  const handleAccountType = (type) => {
    if (type === 1) {
      setAccountType(1);
      setFirstAcc(true);
      setSecondAcc(false);
    } else if (type === 2) {
      setAccountType(2);
      setFirstAcc(false);
      setSecondAcc(true);
    } else {
    }
  };

  const handletogglePassword = () => {
    setTogglePassword(!togglePassword);
  };
  const handletoggleRePassword = () => {
    setToggleRePassword(!toggleRePassword);
  };
  const [isLoading, setIsLoading] = useState(false);
  const [pMessaage, setPMessaage] = useState();
  const [message, setMessage] = useState();
  const [dealerMessage, setDealerMessage] = useState();
  const [loginEmailId, setLoginEmailId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [pwd, setPwd] = useState("");
  const [confPwd, setConfPwd] = useState("");
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(false);

  const [dealerId, setDealerId] = useState("");
  const [firstNameDealer, setFirstNameDealer] = useState("");
  const [lastNameDealer, setLastNameDealer] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [conformPassword, setConformPassword] = useState("");
  const [referralCode, setReferralCode] = useState("");
  const [resendLink, setResendLink] = useState(false);

  const dispatch = useDispatch();
  const [failedValue, setFailedValue] = useState("");
  const [isResetButton, setIsResetButton] = useState(false);
  const [seconds, setSeconds] = useState(30);

  //for dealer signup
  const [signupDealerId, setSignupDealerId] = useState("");
  const [signupDealerFirstName, setSignupDealerFirstName] = useState("");
  const [signupDealerLastName, setSignupDealerLastName] = useState("");
  const [signupDealerEmail, setSignupDealerEmail] = useState("");
  const [signupDealerUsername, setSignupDealerUsername] = useState("");
  const [signupDealerPassword, setSignupDealerPassword] = useState("");
  const [signupDealerConfPassword, setSignupDealerConfPassword] = useState("");
  const [signupDealerReferrelCode, setSignupDealerReferrelCode] = useState("");
  const [verificationId, setVerificationId] = useState("");
  const { id } = useParams();
  const [dealerSignup, setDealerSignup] = useState(false);
  const [dealerSignupSubmit, setDealerSignupSubmit] = useState("");
  const [localStorageValue, setLocalStorageValue] = useState(false);
  let [sameCredentialChecked, setSameCredentialChecked] = useState(true);
  const [dealerReferrelCodeInput, setDealerReferrelCodeInput] = useState("");
  const [dealerNewPassword, setDealerNewPassword] = useState("");
  const [dealerNewConfirmPassword, setDealerNewConfirmPassword] = useState("");
  const [intervalId, setIntervalId] = useState(null);
  const [count, setCount] = useState(0);
  const [showVerificationError, setShowVerificationError] = useState(false);
  const [verifyBtnDisabled, setVerifyBtnDisabled] = useState(false);
  const [resendBtnDisabled, setResendBtnDisabled] = useState(false);
  const [submitDealerBtnDisabled, setSubmitDealerBtnDisabled] = useState(false);
  const modalSliceState = useSelector(selectModalState);

  const [modalClose, setModalClose] = useState(false);

  useEffect(() => {
    if (count == 2) {
      const intervalId = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [count, seconds]);

  useEffect(() => {
    // DealerSignupSubmit
    if (selectOpenModal == false) {
      setModalClose(true);
    }
  }, [selectOpenModal]);

  useEffect(() => {
    if (count === 2) {
      setSeconds(30); // Reset the countdown timer to 30 seconds when count becomes 2
    }
  }, [count]);

  const formattedTime = `${String(Math.floor(seconds / 60)).padStart(
    2,
    "0"
  )}:${String(seconds % 60).padStart(2, "0")}`;

  const [register, setRegister] = useState({
    email: "",
    firstName: "",
    lastName: "",
    password: "",
    conformPassword: "",
    referralCode: "",
  });

  const [registerDealer, setRegisterDealer] = useState({
    email: "",
    firstName: "",
    lastName: "",
    password: "",
    conformPassword: "",
    referralCode,
    dealerId,
    resendLink,
  });
  useEffect(() => {
    setRegister({
      email: loginEmailId,
      firstName: firstName,
      lastName: lastName,
      password: pwd,
      conformPassword: confPwd,
      referralCode: referralCode,
    });
  }, [loginEmailId, firstName, lastName, pwd, confPwd, referralCode]);

  useEffect(() => {
    setRegisterDealer({
      email: email,
      firstName: firstNameDealer,
      lastName: lastNameDealer,
      password: password,
      conformPassword: conformPassword,
      referralCode: referralCode,
      dealerId: dealerId,
      resendLink: resendLink,
    });
  }, [
    email,
    firstName,
    lastName,
    password,
    conformPassword,
    referralCode,
    dealerId,
    resendLink,
  ]);

  useEffect(() => {
    //   //console.log("useeffect for dealer signup===");
    //   setDealerSignupSubmit({
    // //console.log("sameCredentialChecked", sameCredentialChecked);
    // //console.log("signupDealerPassword", signupDealerPassword);
    // //console.log("signupDealerConfPassword", signupDealerConfPassword);
    // //console.log("dealerNewPassword", dealerNewPassword);
    // //console.log("dealerNewConfirmPassword", dealerNewConfirmPassword);
    if (sameCredentialChecked) {
      setValue("firstName", signupDealerFirstName, { shouldValidate: true });
      setValue("lastName", signupDealerLastName, { shouldValidate: true });
      setValue("email", signupDealerEmail, { shouldValidate: true });
      setDealerSignupSubmit({
        email: signupDealerEmail,
        firstName: signupDealerFirstName,
        lastName: signupDealerLastName,
        password: signupDealerPassword,
        conformPassword: signupDealerConfPassword,
        referralCode: signupDealerReferrelCode,
        dealerId: signupDealerId,
        resendLink: resendLink,
        sameCredential: sameCredentialChecked,
      });
    } else {
      setValue("firstName", signupDealerFirstName, { shouldValidate: true });
      setValue("lastName", signupDealerLastName, { shouldValidate: true });
      setValue("email", signupDealerEmail, { shouldValidate: true });
      setDealerSignupSubmit({
        email: signupDealerEmail,
        firstName: signupDealerFirstName,
        lastName: signupDealerLastName,
        password: dealerNewPassword,
        conformPassword: dealerNewConfirmPassword,
        referralCode: signupDealerReferrelCode,
        dealerId: signupDealerId,
        resendLink: resendLink,
        sameCredential: sameCredentialChecked,
      });
    }
  }, [
    email,
    firstName,
    lastName,
    password,
    conformPassword,
    referralCode,
    signupDealerId,
    resendLink,
    dealerNewPassword,
    dealerNewConfirmPassword,
    signupDealerReferrelCode,
    signupDealerReferrelCode,
    sameCredentialChecked,
  ]);
  // }, [signupDealerEmail, signupDealerFirstName, signupDealerLastName, signupDealerPassword, signupDealerConfPassword, referralCode, signupDealerId, resendLink, dealerNewPassword, dealerNewConfirmPassword, signupDealerReferrelCode]);

  // const [dealerSignupSubmit, setDealerSignupSubmit] = useState({
  //   email: email,
  //   firstName: firstNameDealer,
  //   lastName: lastNameDealer,
  //   password: password,
  //   conformPassword: conformPassword,
  //   referralCode: referralCode,
  //   dealerId: dealerId,
  //   resendLink: resendLink,
  // })

  const initialValues = {
    email: "",
    firstName: "",
    lastName: "",
    password: "",
    conformPassword: "", // Updated initial values
    referralCode: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Email is Required").email("Invalid Email"),

    firstName: Yup.string()
      .matches(/^\S*$/, "First Name cannot contain spaces")
      .required("First Name is Required"),

    lastName: Yup.string()
      .matches(/^\S*$/, "Last Name cannot contain spaces")
      .required("Last Name is Required"),

    password: Yup.string()
      .min(8, "Password must be at least 8 characters long")
      .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
      .matches(/[a-z]/, "Password must contain at least one lowercase letter")
      .matches(/[0-9]/, "Password must contain at least one digit")
      .matches(
        /[!@#$%^&*(),.?":{}|<>]/,
        "Password must contain at least one special character"
      )
      .required("Password is Required"),

    conformPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is Required"),

    referralCode: Yup.string(),
  });

  const handleSubmit = (values) => {
    // Updated handleSubmit to use Formik values
    setShowLoader(true);
    RegisterService.registerUser(values)
      .then((response) => {
        setIsLoading(false);

        if (response.status === 200) {
          localStorage.setItem("accesstoken", response.data.token);
          localStorage.setItem("emailId", response.data.email);
          localStorage.setItem("name", response.data.name);
          localStorage.setItem("userId", response.data.id);
          localStorage.setItem("isVerified", response.data.isVerified);
          localStorage.setItem("roleId", response.data.role.roleId);
          localStorage.setItem(
            "reffralCode",
            response.data.referralCode ? response.data.referralCode : ""
          );
          localStorage.setItem("loggedIn", true);
          localStorage.setItem("emailSendSuccess", 1);
          Cookies.set("accesstoken", response.data.token, {
            expires: 7,
            secure: true,
            sameSite: "strict",
          });
          navigate("/");
        } else {
          setMessage("");
        }
      })
      .catch((error) => {
        setShowLoader(false);
        if (error.message === "Network Error") {
          setMessage("Network Error");
        } else {
          switch (error.response ? error.response.status : "") {
            case 401:
              setMessage(error.response.data.failedReason);
              break;
            case 403:
              setMessage(error.response.data.failedReason);
              break;
            case 500:
              //console.log("Internal server error while individual signup",error.response.data.failedReason);
              setMessage("Server error occured. Please try again later.");
              break;
            case 404:
              setMessage(error.response.data.failedReason);
              break;
            case 406:
              setMessage(error.response.data.failedReason);
              break;
            case 412:
              setMessage(error.response.data.failedReason);
              break;
            default:
              setMessage("Unknown error occured. Please try again later.");
              break;
          }
        }
      })
      .finally(() => {
        setShowLoader(false);
      });
  };

  const handleSignupDealer = (data) => {
    //console.log("signup dealer object====", dealerSignupSubmit);
    //console.log("data-----for signup dealer==", data);
    //console.log("toggleRefferalCodeTwo==", toggleRefferalCodeTwo);
    setSubmitDealerBtnDisabled(true);
    //console.log("same cred checkkk===", sameCredentialChecked);

    if (sameCredentialChecked) {
      setDealerSignupSubmit({
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        password: data.password,
        conformPassword: data.conformPassword,
        referralCode: data.referralCode,
        dealerId: data.dealerId,
        resendLink: false,
        sameCredential: sameCredentialChecked,
      });
    } else {
      setDealerSignupSubmit({
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        password: data.newPassword,
        conformPassword: data.rePassword,
        referralCode: data.referralCode,
        dealerId: data.dealerId,
        resendLink: false,
        sameCredential: sameCredentialChecked,
      });
    }
    RegisterService.registerDealerUser(dealerSignupSubmit)
      .then((response) => {
        setSubmitDealerBtnDisabled(false);
        // //console.log("response-----for signup dealer==", response.data);

        if (response.status === 200) {
          localStorage.setItem("accesstoken", response.data.token);
          localStorage.setItem("emailId", response.data.email);
          localStorage.setItem("name", response.data.name);
          localStorage.setItem("userId", response.data.id);
          localStorage.setItem("isVerified", response.data.isVerified);
          localStorage.setItem("roleId", response.data.role.roleId);
          localStorage.setItem(
            "reffralCode",
            response.data.referralCode ? response.data.referralCode : ""
          );
          localStorage.setItem("loggedIn", true);
          navigate("/");
        } else {
          setDealerMessage("");
        }
      })
      .catch((error) => {
        setSubmitDealerBtnDisabled(false);
        if (error.message === "Network Error") {
          setDealerMessage("Network Error");
        } else {
          switch (error.response ? error.response.status : "") {
            case 401:
              setDealerMessage(error.response.data.failedReason);
              break;
            case 403:
              setDealerMessage(error.response.data.failedReason);
              break;
            case 500:
              //console.log("Internal server error while individual signup",error.response.data.failedReason);
              setDealerMessage("Server error occured. Please try again later.");
              break;
            case 404:
              setDealerMessage(error.response.data.failedReason);
              break;
            case 406:
              //console.log("invalid dealer", error.response.data.failedReason);

              setDealerMessage(error.response.data.failedReason);
              //console.log("dealer message==", dealerMessage);
              break;

            case 412:
              setDealerMessage(error.response.data.failedReason);
              break;
            default:
              setDealerMessage(
                "Unknown error occured. Please try again later."
              );
              break;
          }
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleReferalCodeOne = (setFieldValue) => {
    if (toggleRefferalCodeOne) {
      setFieldValue("referralCode", "");
    }
    setToggleRefferalCodeOne(!toggleRefferalCodeOne);
  };
  const handleReferalCodeTwo = () => {
    if (toggleRefferalCodeTwo) {
      //console.log("consle for refferel code==", signupDealerReferrelCode);
      //console.log("toggleRefferalCode Two refferel code==",toggleRefferalCodeTwo);

      setSignupDealerReferrelCode(null);
    }
    setToggleRefferalCodeTwo(!toggleRefferalCodeTwo);
  };
  const handlePasswordGroupToggle = () => {
    setSameCredentialChecked(!sameCredentialChecked);
    setTogglePasswordGroup(!togglePasswordGroup);
    //console.log("sameCredentialChecked2:", sameCredentialChecked);
  };

  useEffect(() => {
    if (!modalSliceState) {
      setOpen(false);
      // localStorage.removeItem("kioskSelectedPlanToBuy");
      // setEmailError("");
      // setEmail();
      setSeconds(30); // Reset the countdown timer to 30 seconds
      clearInterval(intervalId); // Clear the interval for verification API calls
      setCount(0); // Reset the count to 0
    }
  }, [modalSliceState]);
  const backClick = () => {
    // //console.log("here");
    setCount(0);
    clearInterval(intervalId);
  };
  const [resendClicked, setResendClicked] = useState(false);

  const handleVerify = (data) => {
    //console.log("data  in verify dealer ----", data);
    //console.log("dealer  in verify dealer ----", dealerId);

    if (!dealerId) {
      setDealerMessage("Dealer Id is required");
      return;
    }

    if (isNaN(dealerId)) {
      setDealerMessage("Enter valid Dealer Id");
      return;
    }

    if (dealerId <= 0) {
      setDealerMessage("Enter valid Dealer Id");
      return;
    }

    const dealerData = {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      conformPassword: "",
      // dealerId: Number(data.dealerId),
      dealerId: dealerId,
      referralCode: "",
      resendLink: false,
      sameCredential: false,
    };

    setVerifyBtnDisabled(true);
    setDealerId(dealerId);
    // setStepOne(false);
    // setStepTwo(true);
    //console.log("dealer data inside====", dealerData);

    // setShowLoader(true);
    DealerSignupService.dealerSignup(dealerData)

      .then((response) => {
        // setShowLoader(false);
        //console.log("response=====", response);
        setVerifyBtnDisabled(false);
        setDealerMessage("");

        if (response.data.status === "success") {
          //console.log("response====inside =", response.data);
          // setFailedValue(0);
          // dispatch(openModal())
          // localStorage.setItem("token", response.data.token);
          // localStorage.setItem("emailId", response.data.email);
          // localStorage.setItem("name", response.data.name);
          // localStorage.setItem("id", response.data.id);
          // localStorage.setItem("isVerified", response.data.isVerified);
          // localStorage.setItem("loggedIn", true);
          // navigate("/");
          setCount(2);
          dispatch(openModal());

          setSeconds(30);
          let totalTime = 0;
          const interval = setInterval(() => {
            // Call the API every 10 seconds
            // const kioskDetails = localStorage.getItem("kioskLoginDetails")
            //   ? JSON.parse(localStorage.getItem("kioskLoginDetails"))
            //   : undefined;
            //console.log("insideeeeeeee      ");
            VerificationService.getVerificationStatus(dealerId)
              .then((response1) => {
                setDealerMessage("");
                //console.log("response 1 ====", response1);
                //console.log("response 1 ffff====", response1.status);
                //console.log("response 1 data====", response1.data);

                if (response1.status === 200) {
                  if (response1.data.isVerified === 0) {
                    // Continue calling every 10 seconds for 30 seconds
                    totalTime += 10;
                    if (totalTime >= 30) {
                      clearInterval(interval);
                      if (response1.data.isVerified === 0) {
                        //console.log(response1.data);
                        setCount(3);
                        // document.getElementById(
                        //   "resend"
                        // ).style.pointerEvents = "auto";
                      }
                      dispatch(closeModal());
                      //for button
                      clearInterval(interval);
                      setIsResetButton(true);
                      setDealerId(dealerId);
                    }
                  } else if (response1.data.isVerified === 1) {
                    // Redirect to checkout page
                    dispatch(closeModal());
                    clearInterval(interval);
                    // setStepTwo(true);
                    setDealerSignup(true);

                    setAccountType(2);
                    setFirstAcc(false);
                    setSecondAcc(true);
                    setStepOne(true);
                    setStepTwo(true);
                    setReadonlyDealer(true);

                    setDealerId(dealerId);
                    // setSignupDealerId(dealerId)
                    setSignupDealerFirstName(response1.data.firstName);
                    setSignupDealerLastName(response1.data.lastName);
                    setSignupDealerEmail(response1.data.email);
                    setSignupDealerUsername(response1.data.username);
                    setSignupDealerPassword(response1.data.password);
                    setSignupDealerConfPassword(response1.data.password);
                    setSignupDealerId(dealerId);

                    setValue("firstName", response1.data.firstName, {
                      shouldValidate: true,
                    });
                    setValue("lastName", response1.data.lastName, {
                      shouldValidate: true,
                    });
                    setValue("email", response1.data.email, {
                      shouldValidate: true,
                    });
                    setValue("password", response1.data.password, {
                      shouldValidate: true,
                    });
                    setValue("conformPassword", response1.data.password, {
                      shouldValidate: true,
                    });
                    setValue("referralCode", null, { shouldValidate: true });
                    setValue("dealerId", dealerId, { shouldValidate: true });

                    // kisoskSTWPrizeAvailable(response.data.id);
                  }
                }
              })
              // })
              .catch((error) => {
                setVerifyBtnDisabled(false);
                setShowLoader(false);
                if (error.message === "Network Error") {
                  setDealerMessage(
                    "Something went wrong, Please try again later"
                  );
                } else {
                  // Handle other errors
                  switch (error.response ? error.response.status : "") {
                    case 401:
                      // Handle 401 error
                      // localStorage.clear();
                      // localStorage.setItem("kioskSessionExpired", true);
                      // history.push("/kiosk/login");
                      break;
                    case 403:
                      // Handle 403 error
                      break;
                    // Handle other error cases
                    default:
                      setDealerMessage(
                        "Something went wrong, Please try again later"
                      );
                      break;
                  }
                }
              });
          }, 10000); // Repeat every 10 seconds

          setIntervalId(interval);
        } else if (response.data.failedValue === 46) {
          //console.log("======failed value 46==");
          setVerifyBtnDisabled(false);
          setFailedValue(46);
          setResendLink(true);
          setIsResetButton(true);
          dispatch(openModal());
        } else {
          setDealerMessage(undefined);
        }
      })
      .catch((error) => {
        setShowLoader(false);
        setVerifyBtnDisabled(false);
        if (error.message === "Network Error") {
          setMessage("Network Error");
        } else {
          switch (error.response ? error.response.status : "") {
            case 401:
              setDealerMessage(error.response.data.failedReason);
              break;
            case 403:
              setDealerMessage(error.response.data.failedReason);
              break;
            case 500:
              //console.log("Internal server error while individual signup",error.response.data.failedReason);
              setDealerMessage("Server error occured. Please try again later.");
              break;
            case 404:
              setDealerMessage(error.response.data.failedReason);
              break;
            case 406:
              //console.log("invalid dealer", error.response.data.failedReason);
              setDealerMessage(error.response.data.failedReason);
              //console.log("dealer message==", dealerMessage);
              break;

            case 412:
              setDealerMessage(error.response.data.failedReason);
              break;
            default:
              setDealerMessage(
                "Unknown error occured. Please try again later."
              );
              break;
          }
        }
      });
    // .finally(() => {
    //   setShowLoader(false);
    // });
  };

  const handleResend = (data) => {
    //console.log("handle resend dealer id===", dealerId);
    if (!dealerId) {
      setDealerMessage("Dealer Id is required");
      return;
    }

    if (isNaN(dealerId)) {
      setDealerMessage("Enter valid Dealer Id");
      return;
    }

    if (dealerId <= 0) {
      setDealerMessage("Enter valid Dealer Id");
      return;
    }

    const dealerData = {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      conformPassword: "",
      dealerId: dealerId,
      referralCode: "",
      resendLink: true,
      sameCredential: false,
    };

    setResendBtnDisabled(true);
    // setStepOne(false);
    // setStepTwo(true);
    //console.log("dealer data inside====", dealerData);

    // setShowLoader(true);
    DealerSignupService.dealerSignup(dealerData)

      .then((response) => {
        // setShowLoader(false);
        //console.log("response=====", response);
        setResendBtnDisabled(false);
        setDealerMessage("");

        if (response.data.status === "success") {
          //console.log("response====inside =", response.data);
          // setFailedValue(0);
          // dispatch(openModal())
          // localStorage.setItem("token", response.data.token);
          // localStorage.setItem("emailId", response.data.email);
          // localStorage.setItem("name", response.data.name);
          // localStorage.setItem("id", response.data.id);
          // localStorage.setItem("isVerified", response.data.isVerified);
          // localStorage.setItem("loggedIn", true);
          // navigate("/");
          setCount(2);
          dispatch(openModal());

          setSeconds(30);
          let totalTime = 0;
          const interval = setInterval(() => {
            // Call the API every 10 seconds
            // const kioskDetails = localStorage.getItem("kioskLoginDetails")
            //   ? JSON.parse(localStorage.getItem("kioskLoginDetails"))
            //   : undefined;
            //console.log("insideeeeeeee      ");
            VerificationService.getVerificationStatus(dealerId)
              .then((response1) => {
                //console.log("response 1 ====", response1);
                //console.log("response 1 ffff====", response1.status);
                //console.log("response 1 data====", response1.data);
                setDealerMessage("");
                if (response1.status === 200) {
                  if (response1.data.isVerified === 0) {
                    // Continue calling every 10 seconds for 30 seconds
                    totalTime += 10;
                    if (totalTime >= 30) {
                      clearInterval(interval);
                      if (response1.data.isVerified === 0) {
                        //console.log(response1.data);
                        setCount(3);
                        // document.getElementById(
                        //   "resend"
                        // ).style.pointerEvents = "auto";
                      }
                      dispatch(closeModal());
                    }
                  } else if (response1.data.isVerified === 1) {
                    // Redirect to checkout page
                    dispatch(closeModal());
                    clearInterval(interval);
                    setStepTwo(true);
                    setReadonlyDealer(true);

                    setDealerSignup(true);
                    setIsResetButton(false);

                    setAccountType(2);
                    setFirstAcc(false);
                    setSecondAcc(true);
                    setStepOne(true);
                    setStepTwo(true);
                    setReadonlyDealer(true);
                    setDealerId(dealerId);
                    // setSignupDealerId(dealerId)
                    setSignupDealerFirstName(response1.data.firstName);
                    setSignupDealerLastName(response1.data.lastName);
                    setSignupDealerEmail(response1.data.email);
                    setSignupDealerUsername(response1.data.username);
                    setSignupDealerPassword(response1.data.password);
                    setSignupDealerConfPassword(response1.data.password);
                    setSignupDealerId(dealerId);

                    setValue("firstName", response1.data.firstName, {
                      shouldValidate: true,
                    });
                    setValue("lastName", response1.data.lastName, {
                      shouldValidate: true,
                    });
                    setValue("email", response1.data.email, {
                      shouldValidate: true,
                    });
                    setValue("password", response1.data.password, {
                      shouldValidate: true,
                    });
                    setValue("conformPassword", response1.data.password, {
                      shouldValidate: true,
                    });
                    setValue("referralCode", null, { shouldValidate: true });
                    setValue("dealerId", dealerId, { shouldValidate: true });
                  }
                }
              })
              // })
              .catch((error) => {
                setResendBtnDisabled(false);
                setShowLoader(false);
                if (error.message === "Network Error") {
                  setDealerMessage(
                    "Something went wrong, Please try again later"
                  );
                } else {
                  // Handle other errors
                  switch (error.response ? error.response.status : "") {
                    case 401:
                      // Handle 401 error
                      // localStorage.clear();
                      // localStorage.setItem("kioskSessionExpired", true);
                      // history.push("/kiosk/login");
                      break;
                    case 403:
                      // Handle 403 error
                      break;
                    // Handle other error cases
                    default:
                      setDealerMessage(
                        "Something went wrong, Please try again later"
                      );
                      break;
                  }
                }
              });
          }, 10000); // Repeat every 10 seconds

          setIntervalId(interval);
        } else {
          setDealerMessage(undefined);
        }
      })
      .catch((error) => {
        setResendBtnDisabled(false);
        setShowLoader(false);
        if (error.message === "Network Error") {
          setMessage("Network Error");
        } else {
          switch (error.response ? error.response.status : "") {
            case 401:
              setDealerMessage(error.response.data.failedReason);
              break;
            case 403:
              setDealerMessage(error.response.data.failedReason);
              break;
            case 500:
              //console.log("Internal server error while individual signup",error.response.data.failedReason);
              setDealerMessage("Server error occured. Please try again later.");
              break;
            case 404:
              setDealerMessage(error.response.data.failedReason);
              break;
            case 406:
              //console.log("invalid dealer", error.response.data.failedReason);
              setDealerMessage(error.response.data.failedReason);
              //console.log("dealer message==", dealerMessage);
              break;

            case 412:
              setDealerMessage(error.response.data.failedReason);
              break;
            default:
              setDealerMessage(
                "Unknown error occured. Please try again later."
              );
              break;
          }
        }
      });
  };

  const checkVerificationStatus = (data) => {
    //console.log("console for custome dealer id===", dealerId);
    setShowLoader(true);
    setVerifyBtnDisabled(true);
    //console.log("console for custome dealer id===", dealerId);
    VerificationService.getVerificationStatus(dealerId)
      .then((response1) => {
        setShowLoader(false);
        setVerifyBtnDisabled(false);

        //console.log("response 1 ====", response1);
        //console.log("response 1 ffff====", response1.status);
        //console.log("response 1 data====", response1.data);

        if (response1.status === 200) {
          if (response1.data.isVerified === 0) {
            setDealerMessage("Dealer is not verified yet. Please check email.");
          } else if (response1.data.isVerified === 1) {
            setDealerMessage("");

            // Redirect to checkout page
            dispatch(closeModal());
            // clearInterval(interval);
            // setStepTwo(true);
            setDealerSignup(true);
            setIsResetButton(false);

            setAccountType(2);
            setFirstAcc(false);
            setSecondAcc(true);
            setStepOne(true);
            setStepTwo(true);
            setReadonlyDealer(true);
            setDealerId(dealerId);
            // setSignupDealerId(dealerId)
            setSignupDealerFirstName(response1.data.firstName);
            setSignupDealerLastName(response1.data.lastName);
            setSignupDealerEmail(response1.data.email);
            setSignupDealerUsername(response1.data.username);
            setSignupDealerPassword(response1.data.password);
            setSignupDealerConfPassword(response1.data.password);
            setSignupDealerId(dealerId);

            setValue("firstName", response1.data.firstName, {
              shouldValidate: true,
            });
            setValue("lastName", response1.data.lastName, {
              shouldValidate: true,
            });
            setValue("email", response1.data.email, { shouldValidate: true });
            setValue("password", response1.data.password, {
              shouldValidate: true,
            });
            setValue("conformPassword", response1.data.password, {
              shouldValidate: true,
            });
            setValue("referralCode", null, { shouldValidate: true });
            setValue("dealerId", dealerId, { shouldValidate: true });

            // kisoskSTWPrizeAvailable(response.data.id);
          }
        }
      })
      // })
      .catch((error) => {
        setShowLoader(false);
        setVerifyBtnDisabled(false);
        if (error.message === "Network Error") {
          setDealerMessage("Something went wrong, Please try again later");
        } else {
          // Handle other errors
          switch (error.response ? error.response.status : "") {
            case 401:
              setDealerMessage(error.response.data.failedReason);
              // Handle 401 error
              // localStorage.clear();
              // localStorage.setItem("kioskSessionExpired", true);
              // history.push("/kiosk/login");
              break;
            case 403:
              setDealerMessage(error.response.data.failedReason);
              // Handle 403 error
              break;
            // Handle other error cases
            default:
              setDealerMessage("Something went wrong, Please try again later");
              break;
          }
        }
      });
  };
  // //for dealer verification
  // //console.log("id in rgister====",id);
  // useEffect(() => {
  //   if (id !== undefined && id !== null && id !== "") {
  //     setShowLoader(true)
  //     //console.log("idd====", id);
  //     VerificationService.doVerification(id)
  //       .then((response) => {
  //         setShowLoader(false);
  //         //console.log("response=====", response.data);
  //         //console.log("response tttt=====", response.status);

  //         if (response.status === 200) {
  //           localStorage.setItem("accesstoken", response.data.token);
  //           localStorage.setItem("emailId", response.data.email);
  //           localStorage.setItem("name", response.data.name);
  //           localStorage.setItem("username", response.data.id);

  //           setPMessaage("login successful");
  //           navigate("/");

  //         } else if (response.status === 202) {
  //           // localStorage.setItem("dealerAccesstoken", response.data.token);
  //           // localStorage.setItem("dealerEmailId", response.data.email);
  //           // localStorage.setItem("dealerFirstName", response.data.firstName);
  //           // localStorage.setItem("dealerLastName", response.data.lastName);
  //           // localStorage.setItem("dealerId", response.data.dealerId);
  //           // localStorage.setItem("dealerPassword", response.data.password);
  //           // localStorage.setItem("dealerUsername", response.data.username);
  //           // localStorage.setItem("dealerSignup", response.data.dealerSignup);
  //           // setLocalStorageValue(true);
  //           //console.log("rsponse 202=====");
  //           navigate("/VerificationResponse");

  //         }
  //         else {
  //           setPMessaage("");
  //         }
  //       })
  //       .catch((error) => {
  //         setShowLoader(false);
  //         if (error.message === "Network Error") {
  //           setMessage("Network Error");
  //         } else {
  //           //console.log("errrrroorrr====", error.response);

  //           switch (error.response ? error.response.status : "") {
  //             case 401:
  //               break;
  //             case 403:
  //               break;
  //             case 500:
  //             case 404:
  //             case 406:
  //               setMessage(error.response.data.failedReason);
  //               break;
  //             case 412:
  //               break;
  //             default:
  //               setMessage("unknown Error");
  //               break;
  //           }
  //         }
  //       })
  //     .finally(() => {
  //       setShowLoader(false);
  //     });

  //   }
  // }, []);

  // useEffect(() => {
  //   //console.log("inside useeffetc", localStorage);
  //   const dealerId = localStorage.getItem("dealerId");
  //   //console.log("deale riddddd===========", dealerId);
  //   const dealerFirstName = localStorage.getItem("dealerFirstName");
  //   const dealerLastName = localStorage.getItem("dealerLastName");
  //   const dealerEmailId = localStorage.getItem("dealerEmailId");
  //   const dealerUsername = localStorage.getItem("dealerUsername");
  //   const dealerPassword = localStorage.getItem("dealerPassword");
  //   const dealerSignup = localStorage.getItem("dealerSignup");
  //   // const dealerSignup = localStorage.getItem("dealerSignup");
  //   //console.log("isDealer==", dealerId);
  //   //console.log("dealerFirstName==", dealerFirstName);
  //   //console.log("dealerSignup==", dealerSignup);
  //   if (dealerId !== undefined && dealerId !== null && dealerId !== "" && dealerId != 0) {
  //     //console.log("dealerFirstName==", dealerFirstName);
  //     setAccountType(2);
  //     setFirstAcc(false);
  //     setSecondAcc(true);
  //     setStepOne(true);
  //     setStepTwo(true);
  //     setDealerId(dealerId);
  //     // setSignupDealerId(dealerId)
  //     setSignupDealerFirstName(dealerFirstName);
  //     setSignupDealerLastName(dealerLastName);
  //     setSignupDealerEmail(dealerEmailId);
  //     setSignupDealerUsername(dealerUsername);
  //     setSignupDealerPassword(dealerPassword);
  //     setDealerSignup(true);
  //     setSignupDealerConfPassword(dealerPassword);
  //     setSignupDealerId(dealerId);

  //   }

  // }, [localStorageValue]);

  const validatePasswords = (value) => {
    return value == watch("newPassword") || "Passwords do not match";
  };

  const passwordValidationRules = {
    required: "Password is required",
    minLength: { value: 8, message: "Password must be at least 8 characters" },
    pattern: {
      value:
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      message:
        "Password must include 1 uppercase, 1 lowercase, 1 number, and 1 symbol",
    },
  };

  // const handleSignupDealerForDealer = (data) => {
  // //console.log("Sign up dealer", data);
  // };
  // const handleResendForDealer = () => {
  // const dealerId = getValues("dealerId");
  // //console.log("Resend for dealerId:", dealerId);
  // };
  // const handleVerifyForDealer = () => {
  // const dealerId = getValues("dealerId");
  // //console.log("Resend for dealerId:", dealerId);
  // };
  // const checkVerificationStatusForDealer = () => {
  // const dealerId = getValues("dealerId");
  // //console.log("Check verification status for dealerId:", dealerId);
  // };
  // Form submit handler
  // const onDealerSubmit = (data, event) => {

  //   const res = data
  //   let buttonName = null;
  //   //console.log(event.target.children, "Button");

  //   if (event.target.tagName === 'button') {
  //       buttonName = event.target.name; // Accessing the 'name' property from the button element
  //   }

  //   //console.log("Button name:", buttonName);

  //   if (res) {
  //     // if (isResetButton) {
  //     //   handleResend();
  //     // } else {
  //     //   handleVerify(res);
  //     // }
  //     // event.preventDefault();

  //       //console.log(buttonName, "buttonType");
  //     switch (buttonName) {
  //       case "submitDealer":
  //         handleSignupDealer(data);
  //         break;
  //       case "verifyDealer":
  //         //console.log("inside buttn 22");

  //         handleVerify(res);
  //         break;
  //       case "resendDealer":
  //         handleResend(res);
  //         break;
  //       case "checkVerificationStatus":
  //         checkVerificationStatus(res);
  //         break;
  //       default:
  //         break;
  //     }
  //   }
  // };

  return (
    <>
      {
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
          <div className="mx-auto w-full max-w-sm md:max-w-md">
            <Link to="/">
              <img className="mx-auto h-[80px] w-auto" src={logo} alt="GKT" />
            </Link>
            <div className="flex flex-col justify-center items-center">
              <h2 className="text-center text-2xl font-semibold leading-9 tracking-tight primary-text">
                Create Account
              </h2>
              <p className="text-lg font-medium mt-4">
                Choose Your Sign Up Type
              </p>
            </div>
            {/* Account tabs */}
            <div className="border custom-border-full flex mt-2 mb-4 rounded-md p-[1px]">
              <button
                onClick={() => handleAccountType(1)}
                className={`w-full h-8 text-sm rounded-md py-1 px-2 ${
                  accountType === 1 ? "login-active" : ""
                }`}
              >
                Customer Sign Up
              </button>
              <button
                onClick={() => handleAccountType(2)}
                className={`w-full h-8 text-sm rounded-md py-1 px-2 ${
                  accountType === 2 ? "login-active" : ""
                }`}
              >
                Business Sign Up
              </button>
            </div>
          </div>
          <div className="mt-4 mx-auto w-full max-w-sm md:max-w-md">
            {firstAcc ? (
              <Formik
                initialValues={initialValues} // Added initial values
                validationSchema={validationSchema} // Added validation schema
                onSubmit={handleSubmit} // Added handleSubmit
              >
                {({ errors, touched, setFieldValue }) => (
                  <Form className="space-y-6">
                    {" "}
                    {/* Changed to Formik Form */}
                    <div className="flex md:flex-row flex-col gap-3">
                      <div className="md:basis-1/2 basis-1">
                        <label
                          htmlFor="firstName"
                          className="block text-sm font-normal leading-6"
                        >
                          First Name*
                        </label>
                        <div className="mt-2">
                          <Field
                            id="firstName"
                            name="firstName" // Updated Field name
                            type="text"
                            autoComplete="first-name"
                            placeholder="Enter your first name"
                            className={`block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-30 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6 ${
                              errors.firstName && touched.firstName
                                ? "border-red-500"
                                : ""
                            }`}
                          />
                        </div>
                        <ErrorMessage
                          name="firstName"
                          component="div"
                          className="error-text "
                        />{" "}
                        {/* Added ErrorMessage */}
                      </div>
                      <div className="md:basis-1/2 basis-1">
                        <label
                          htmlFor="lastName"
                          className="block text-sm font-normal leading-6"
                        >
                          Last Name*
                        </label>
                        <div className="mt-2">
                          <Field
                            id="lastName"
                            name="lastName" // Updated Field name
                            type="text"
                            autoComplete="last-name"
                            placeholder="Enter your last name"
                            className={`block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-30 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6 ${
                              errors.lastName && touched.lastName
                                ? "border-red-500"
                                : ""
                            }`}
                          />
                        </div>
                        <ErrorMessage
                          name="lastName"
                          component="div"
                          className="error-text "
                        />{" "}
                        {/* Added ErrorMessage */}
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor="email"
                        className="block text-sm font-normal leading-6"
                      >
                        Email address*
                      </label>
                      <div className="mt-2">
                        <Field
                          id="email"
                          name="email" // Updated Field name
                          type="email"
                          placeholder="Your e-mail address"
                          autoComplete="off"
                          className={`block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6 ${
                            errors.email && touched.email
                              ? "border-red-500"
                              : ""
                          }`}
                        />
                      </div>
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="error-text "
                      />{" "}
                      {/* Added ErrorMessage */}
                    </div>
                    <div>
                      <div className="flex items-center justify-between">
                        <label
                          htmlFor="password"
                          className="block text-sm font-normal leading-6"
                        >
                          Password*
                        </label>
                      </div>
                      <div className="mt-2 relative">
                        <Field
                          id="password"
                          name="password" // Updated Field name
                          type={togglePassword ? "text" : "password"}
                          autoComplete="off"
                          placeholder="Enter a password"
                          className={`block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6 ${
                            errors.password && touched.password
                              ? "border-red-500"
                              : ""
                          }`}
                        />
                        <div
                          onClick={handletogglePassword}
                          className="absolute top-1/2 -translate-y-1/2 right-2 w-[18px] cursor-pointer"
                        >
                          {togglePassword ? <EyeSlashIcon /> : <EyeIcon />}
                        </div>
                      </div>
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="error-text "
                      />{" "}
                      {/* Added ErrorMessage */}
                    </div>
                    <div>
                      <div className="flex items-center justify-between">
                        <label
                          htmlFor="conformPassword"
                          className="block text-sm font-normal leading-6"
                        >
                          Confirm your password*
                        </label>
                      </div>
                      <div className="mt-2 relative">
                        <Field
                          id="conformPassword"
                          name="conformPassword" // Updated Field name
                          type={toggleRePassword ? "text" : "password"}
                          autoComplete="off"
                          placeholder="Re-enter a password"
                          className={`block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6 ${
                            errors.conformPassword && touched.conformPassword
                              ? "border-red-500"
                              : ""
                          }`}
                        />
                        <div
                          onClick={handletoggleRePassword}
                          className="absolute top-1/2 -translate-y-1/2 right-2 w-[18px] cursor-pointer"
                        >
                          {toggleRePassword ? <EyeSlashIcon /> : <EyeIcon />}
                        </div>
                      </div>
                      <ErrorMessage
                        name="conformPassword"
                        component="div"
                        className="error-text "
                      />{" "}
                      {/* Added ErrorMessage */}
                    </div>
                    <div className="flex justify-start items-start">
                      <label className="custom-checkbox">
                        <div>
                          <input
                            checked={toggleRefferalCodeOne}
                            // onChange={handleReferalCodeOne}
                            onChange={() => handleReferalCodeOne(setFieldValue)}
                            type="checkbox"
                            className="focus:ring-teal-600"
                            id="refCode"
                          />
                        </div>
                        <div className="text-sm leading-6">
                          <label htmlFor="refCode" className="font-medium">
                            Do you have a referral code?
                          </label>
                        </div>
                      </label>
                    </div>
                    <Transition
                      show={toggleRefferalCodeOne}
                      enter="transition-all duration-200"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="transition-all duration-200"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div style={{ marginTop: 16 }}>
                        <label
                          htmlFor="referralCode"
                          className="block text-sm font-normal leading-6"
                        >
                          Referral Code
                        </label>
                        <div className="mt-2">
                          {/* <input
                              id="referralCode"
                              name="referralCode"
                              type="text"
                              placeholder="Enter your referral Code"
                              className="block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-30 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                            /> */}
                          <Field
                            id="referralCode"
                            name="referralCode" // Updated Field name
                            type="text"
                            placeholder="Enter your referral Code"
                            className="block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-30 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                          />
                        </div>
                        <ErrorMessage
                          name="referralCode"
                          component="div"
                          className="error-text "
                        />{" "}
                      </div>
                    </Transition>
                    {message ? <p className="error-text">{message}</p> : null}
                    <div>
                      <button
                        type="submit"
                        className="flex w-full justify-center rounded-md cta-one px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                      >
                        Sign up
                      </button>
                      {isLoading && <p>Loading...</p>}
                    </div>
                  </Form>
                )}
              </Formik>
            ) : secondAcc ? (
              <form
                className="space-y-6"
                onSubmit={handleSubmitDealer(handleSignupDealer)}
              >
                <div>
                  <label
                    htmlFor="dealer-id"
                    className="block text-sm font-normal leading-6"
                  >
                    Dealer ID
                  </label>
                  <div className="mt-2">
                    <input
                      className={`${
                        readonlyDealer
                          ? "bg-teal-700/20 pointer-events-none readonly-field"
                          : ""
                      } block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-30 focus:ring-1 focus-visible:ring-teal-600 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6`}
                      // {...registerDealerValidation("dealerId", {
                      //   validate: validateDealerId
                      // })}

                      value={dealerId}
                      disabled={readonlyDealer ? true : false}
                      onChange={(e) => {
                        setDealerId(e.target.value);
                      }}
                      type="text"
                      id="dealerId"
                      // name="dealer-id"
                      // type="number"
                      autoComplete="off"
                      placeholder="Enter your dealer id"
                    />
                    {dealerError.dealerId && (
                      <p className="error-text ">
                        {dealerError.dealerId.message}
                      </p>
                    )}
                    {/* {
                    message ? <p className="error-text">{message}</p> : null
                  } */}
                  </div>
                </div>
                {dealerId !== undefined ? (
                  <>
                    <div>
                      {isResetButton ? (
                        <>
                          {dealerMessage ? (
                            <p className="error-text">{dealerMessage}</p>
                          ) : null}
                          {/* <button
                              type="button"
                              onClick={handleResend}
                              className="flex w-full justify-center rounded-md cta-one px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                            >
                              Resend
                            </button> */}
                          <div className="flex space-x-2">
                            <LoaderButton
                              type="button"
                              title="Resend"
                              name="resendDealer"
                              handleClick={handleResend}
                              style="flex w-full justify-center rounded-md cta-one px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                              disabled={resendBtnDisabled}
                            />

                            <LoaderButton
                              type="button"
                              title="Check Verification Status"
                              name="checkVerificationStatusDealer"
                              handleClick={checkVerificationStatus}
                              style="flex w-full justify-center rounded-md cta-one px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                              disabled={verifyBtnDisabled}
                            />
                          </div>
                        </>
                      ) : (
                        !dealerSignup && (
                          <>
                            {dealerMessage ? (
                              <p className="error-text">{dealerMessage}</p>
                            ) : null}

                            <LoaderButton
                              type="button"
                              title="Verify"
                              // name="verifyDealer"
                              handleClick={handleVerify}
                              style="flex w-full justify-center rounded-md cta-one px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                              disabled={verifyBtnDisabled}
                            />
                            {/* <button
                                type="submit"
                                // onClick={handleVerify}
                                className="flex w-full justify-center rounded-md cta-one px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                              >
                                Verify
                              </button> */}
                          </>
                        )
                      )}
                    </div>
                    {secondAcc && stepOne
                      ? !dealerSignup && (
                          <p className="mt-6 text-center text-sm text-gray-400 flex justify-center items-center">
                            <InformationCircleIcon className="w-4 mr-[0.35rem]" />
                            <span>
                              Enter your dealer id to verify your account
                            </span>
                          </p>
                        )
                      : null}
                  </>
                ) : null}
                {
                  // //console.log(dealerId, "dealerId from localstorage")
                }
                {dealerId !== null && stepTwo ? (
                  <>
                    <div className="flex md:flex-row flex-col gap-3">
                      <div className="md:basis-1/2 basis-1">
                        <label
                          htmlFor="first-name"
                          className="block text-sm font-normal leading-6"
                        >
                          First Name*
                        </label>
                        <div className="mt-2">
                          <input
                            {...registerDealerValidation("firstName", {
                              required: "First Name is required",
                              pattern: {
                                value: /^[^\s]*$/,
                                message: "First Name cannot contain spaces",
                              },
                            })}
                            id="firstName"
                            value={signupDealerFirstName}
                            onChange={(e) => {
                              setSignupDealerFirstName(e.target.value);
                            }}
                            // name="firstName"
                            autoComplete="off"
                            type="text"
                            placeholder="Enter your first name"
                            className="block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-30 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                          />
                          {dealerError["firstName"] && (
                            <p className="error-text ">
                              {dealerError["firstName"].message}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="md:basis-1/2 basis-1">
                        <label
                          htmlFor="name"
                          className="block text-sm font-normal leading-6"
                        >
                          Last Name*
                        </label>
                        <div className="mt-2">
                          <input
                            {...registerDealerValidation("lastName", {
                              required: "Last Name is required",
                              pattern: {
                                value: /^[^\s]*$/,
                                message: "Last Name cannot contain spaces",
                              },
                            })}
                            id="lastName"
                            value={signupDealerLastName}
                            onChange={(e) => {
                              setSignupDealerLastName(e.target.value);
                            }}
                            // name="lastName"
                            type="text"
                            autoComplete="off"
                            placeholder="Your last name"
                            // required
                            className="block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-30 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                          />
                          {dealerError["lastName"] && (
                            <p className="error-text ">
                              {dealerError["lastName"].message}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>

                    <div>
                      <label
                        // htmlFor="email"
                        className="block text-sm font-normal leading-6"
                      >
                        Email address
                      </label>
                      <div className="mt-2">
                        <input
                          {...registerDealerValidation("email", {
                            required: "Email is required",
                          })}
                          id="email"
                          readOnly={dealerId !== undefined ? true : false}
                          value={signupDealerEmail}
                          onChange={(e) => {
                            setSignupDealerEmail(e.target.value);
                          }}
                          autoComplete="off"
                          // name="email"
                          type="email"
                          placeholder="Your e-mail address"
                          // autoComplete="email"
                          // required
                          className={`${
                            readonlyDealer
                              ? "bg-teal-700/20 pointer-events-none"
                              : ""
                          } "block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-30 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"`}
                        />
                      </div>
                    </div>

                    <div className="flex justify-start items-start">
                      <label className="custom-checkbox">
                        <div>
                          <input
                            checked={sameCredentialChecked}
                            onChange={handlePasswordGroupToggle}
                            type="checkbox"
                            className="focus:ring-teal-600"
                            id="sameCred"
                          />
                        </div>
                        <div className="text-sm leading-6">
                          <label htmlFor="sameCred" className="font-medium">
                            Continue with the same credentials
                          </label>
                        </div>
                      </label>
                    </div>
                    {togglePasswordGroup && (
                      <>
                        <div>
                          <div className="flex items-center justify-between">
                            <label
                              htmlFor="password"
                              className="block text-sm font-normal leading-6"
                            >
                              New Password*
                            </label>
                          </div>
                          <div className="mt-2 relative">
                            <input
                              {...registerDealerValidation(
                                "newPassword",
                                passwordValidationRules
                              )}
                              id="newPassword"
                              // id="password"
                              onChange={(e) => {
                                setDealerNewPassword(e.target.value);
                              }}
                              placeholder="Create a new password"
                              // name="password"
                              type={togglePassword ? "text" : "password"}
                              // autoComplete="current-password"
                              className="block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-30 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                              // className="placeholder:font-normal font-medium block w-full rounded-md border-0 py-1.5 bg-transparent primary-text shadow-sm ring-1 ring-inset ring-teal-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6 pr-7"
                            />
                            <div
                              onClick={handletogglePassword}
                              className="absolute top-1/2 -translate-y-1/2 right-2 w-[18px] cursor-pointer"
                            >
                              {togglePassword ? <EyeSlashIcon /> : <EyeIcon />}
                            </div>
                          </div>
                          {dealerError.newPassword && (
                            <p className="error-text ">
                              {dealerError.newPassword.message}
                            </p>
                          )}
                        </div>
                        <div>
                          <div className="flex items-center justify-between">
                            <label
                              htmlFor="rePassword"
                              className="block text-sm font-normal leading-6"
                            >
                              Confirm your password
                            </label>
                          </div>
                          <div className="mt-2 relative">
                            <input
                              {...registerDealerValidation("rePassword", {
                                required: "Password is required",
                                validate: validatePasswords,
                              })}
                              // {...registerDealerValidation("rePassword", {
                              //   required: "Password is required",
                              //   validate: validatePasswords,
                              // })}
                              // value={signupDealerPassword}
                              id="rePassword"
                              // id="rePassword"
                              onChange={(e) => {
                                setDealerNewConfirmPassword(e.target.value);
                              }}
                              // name="rePassword"
                              type={toggleRePassword ? "text" : "password"}
                              // autoComplete="current-password"
                              placeholder="Re-enter a password"
                              className="block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-30 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                            />
                            <div
                              onClick={handletoggleRePassword}
                              className="absolute top-1/2 -translate-y-1/2 right-2 w-[18px] cursor-pointer"
                            >
                              {toggleRePassword ? (
                                <EyeSlashIcon />
                              ) : (
                                <EyeIcon />
                              )}
                            </div>
                          </div>
                          {dealerError.rePassword && (
                            <p className="error-text ">
                              {dealerError.rePassword.message}
                            </p>
                          )}
                        </div>
                      </>
                    )}

                    <div className="flex justify-start items-start">
                      <label className="custom-checkbox">
                        <div>
                          <input
                            checked={toggleRefferalCodeTwo}
                            onChange={handleReferalCodeTwo}
                            type="checkbox"
                            className="focus:ring-teal-600"
                            id="refCode"
                          />
                        </div>
                        <div className="text-sm leading-6">
                          <label htmlFor="refCode" className="font-medium">
                            Do you have a referral code?
                          </label>
                        </div>
                      </label>
                    </div>
                    <Transition
                      show={toggleRefferalCodeTwo}
                      enter="transition-all duration-200"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="transition-all duration-200"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div style={{ marginTop: 16 }}>
                        <label
                          htmlFor="referralCode"
                          className="block text-sm font-normal leading-6"
                        >
                          Referral Code
                        </label>
                        <div className="mt-2">
                          <input
                            {...registerDealerValidation("referralCode")}
                            id="referralCode"
                            onChange={(e) => {
                              //console.log("e    ==", e, toggleRefferalCodeTwo);
                              toggleRefferalCodeTwo
                                ? setSignupDealerReferrelCode(e.target.value)
                                : setSignupDealerReferrelCode(null);
                              // setSignupDealerReferrelCode(e.target.value)

                              //console.log("checkbox value==",toggleRefferalCodeTwo);
                              //console.log("signupDealerReferrelCode value==",signupDealerReferrelCode);
                            }}
                            name="referralCode"
                            type="text"
                            placeholder="Enter your referral Code"
                            className="block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-30 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                    </Transition>
                  </>
                ) : null}

                {secondAcc && stepOne ? null : (
                  <>
                    {/* {
                        dealerMessage ? <p className="error-text">{dealerMessage}</p> : null
                      }
                      <div>
                        <button
                          type="submit"
                          className="flex w-full justify-center rounded-md cta-one px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                        >
                          Sign up
                        </button>
                      </div> */}
                  </>
                )}
                {dealerId !== null && stepTwo ? (
                  <>
                    {dealerMessage ? (
                      <p className="error-text">{dealerMessage}</p>
                    ) : null}
                    <div>
                      <LoaderButton
                        type="submit"
                        title="Sign up"
                        name="submitDealer"
                        style="flex w-full justify-center rounded-md cta-one px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                        // disabled={submitDealerBtnDisabled}
                      />
                    </div>
                  </>
                ) : null}
              </form>
            ) : null}

            <p className="mt-6 text-center text-sm ">
              <span className="text-gray-500">Already have an Account? </span>
              <Link
                to="/login"
                className="font-semibold hover:underline decoration-1 primary-text"
              >
                Login
              </Link>
            </p>
          </div>
          <ModalComponent
            size="md"
            oncl
            type="pending"
            position="center"
            description=""
            bottomLine={true}
            title="Verification pending"
          >
            <div className="w-100 d-flex flex-column justify-content-center align-items-center mt-3">
              {count == 0 || count == 1 ? (
                <>
                  <h3 className="mb-3 text-center">
                    Verification Link is Already sent to your email. Please
                    click "Resend" button to get the verification link
                  </h3>

                  <Button
                    handleClick={() => {
                      dispatch(closeModal());

                      // if (failedValue == 39 || failedValue == 40) {
                      //   setFailedValue("");
                      // }
                    }}
                    title="Okay"
                    type="button"
                    style="cta-two w-full rounded text-sm px-4 lg:px-5 py-2 lg:py-2.5 mx-auto mt-2 bg-transparent  hover:text-white focus:ring-4 focus:outline-none font-semibold px-3 py-1.5 text-center"
                  />
                </>
              ) : count == 2 ? (
                <>
                  {/* <FaPaperPlane className="text-[42px] text-center" /> */}
                  {/* <h2 className="kiosk-title text-center">Verify email</h2> */}
                  <h3 className="mb-3 text-center">
                    Please check your email for confirmation mail and click on
                    “Verify” button to continue.
                  </h3>
                  {/* <div className="">
                      <p className="flex">
                        Did not receive email?{" "}
                        <span
                          className="font-weight-bold underline"
                          // onClick={resendEmailClick}
                          id="resend"
                        >
                          Resend email
                        </span>
                      </p>
                    </div> */}
                  <div className="flex flex-col items-center justify-center">
                    <FiClock className="text-[42px]" />
                    <h2 className="font-bold">{formattedTime}</h2>
                    <span className="info">
                      *Verification status checking is in progress
                    </span>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
            {/* <div className="flex flex-col">
                {failedValue == 0 ? <p>
                  A verification link has been sent to your registered email address, Please click on <span className="font-semibold">"Verify Email"</span>  button to verify
                </p> : <p>
                  A verification link has been expired or not received to your registered email address, Please click on <span className="font-semibold">"Resend"</span>  button to verify
                </p>
                }
                <Button
                  handleClick={() => {
                    dispatch(closeModal());
                    setDealerId("");
                    setMessage("");
                    //console.log("failedValue=====", failedValue);

                    if (failedValue == 46) {
                      setIsResetButton(true);
                      setFailedValue("");
                    }
                  }}
                  title="Okay"
                  type="button"
                  style="rounded mx-auto w-1/2 text-sm px-4 lg:px-5 py-2 lg:py-2.5 cta-one rounded text-sm px-4 lg:px-5 py-2 lg:py-2.5 mt-4 mx-auto font-semibold px-3 py-1.5 text-center"
                />
              </div> */}
          </ModalComponent>
          {showLoader && <Loader />}
        </div>
      }
    </>
  );
}
