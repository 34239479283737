import React from "react";
import brand2 from "../../assets/brand-logo/giffgaff_logo.png";
import brand3 from "../../assets/brand-logo/gkt_logo.png";
import brand5 from "../../assets/brand-logo/O2_logo.png";
import brand4 from "../../assets/brand-logo/talkMobile_logo.png";
import brand1 from "../../assets/brand-logo/three_logo.png";
import brand6 from "../../assets/brand-logo/vodafone_logo.png";
import { RiDiscountPercentLine } from "react-icons/ri";

const ProductCard = ({ data, brandIndex, handleBuyNow, my }) => {
  const brands = [
    {
      id: 0,
      img: brand1,
    },
    {
      id: 1,
      img: brand2,
    },
    {
      id: 2,
      img: brand3,
    },
    {
      id: 3,
      img: brand4,
    },
    {
      id: 4,
      img: brand5,
    },
    {
      id: 5,
      img: brand6,
    },
    {
      id: 6,
      img: brand2,
    },
    {
      id: 7,
      img: brand3,
    },
  ];

  return (
    <>
      <div className="flex flex-col border custom-border-50 rounded-lg p-4">
        <div className={`grid grid-cols-3 ${my ? "mt-0" : "mt-4"} `}>
          <div className="flex flex-col justify-center items-center">
            {/* <h3 className="text-lg font-semibold">{data.data} GB</h3> */}
            <h3 className="text-lg font-semibold">
              {data.data === -1 ? "Unlimited" : data.data}{" "}
              {data.data === -1 ? "" : "GB"}
            </h3>
            <p>Data</p>
          </div>
          {/* <div className="break-line"></div> */}
          <div className="flex flex-col justify-center items-center middle-box">
            <h3 className="text-lg font-semibold">
              {" "}
              {data.noOfMinutes === -1 ? "Unlimited" : data.noOfMinutes}
            </h3>
            <p>Minutes</p>
          </div>
          {/* <div className="break-line"></div> */}
          <div className="flex flex-col justify-center items-center">
            <h3 className="text-lg font-semibold">
              {data.noOfText === -1 ? "Unlimited" : data.noOfText}
            </h3>
            <p>Text</p>
          </div>
        </div>
        <div className="grid grid-cols-3 mt-4">
          <div className="flex flex-col justify-center items-center">
            <img
              className="h-8 md:h-10 w-auto"
              src={brands[brandIndex].img}
              alt="network 3"
            />
            {/* <p className="font-semibold">Data</p> */}
          </div>
          <div className="flex flex-col justify-center items-center middle-box">
            <h4 className="text-md font-semibold">
              {data.contractTerm} Months
            </h4>
            <p>Duration</p>
          </div>
          <div className="flex flex-col justify-center items-center relative">
            <div className="flex justify-center items-center relative w-full">
              <h4 className="text-lg font-semibold">£{data.finalPrice}</h4>
            </div>
            <div className="relative flex justify-center items-center">
              <p className="text-md strikethrough px-1">
                £{data.tariffRecurringCharge}
              </p>
            </div>
            <span className="absolute top-0 right-0 w-[44px] h-[44px] p-2 leading-none text-[0.6rem] font-medium bg-red-500/70 text-white border rounded-full flex flex-col justify-center items-center">
              <span className="text-[0.7rem] font-semibold">{Math.ceil(data.discountPercent)}%</span>
              <span>OFF</span>
            </span>
          </div>
        </div>

        {handleBuyNow && (
          <div className="w-full flex justify-center items-center mt-4">
            {/* <Link className="w-1/2" to="/productDetails"> */}
            <button
              className="w-full cta-one py-2 px-4 rounded-md"
              onClick={() => handleBuyNow(data)}
            >
              Buy Now
            </button>
            {/* </Link> */}
          </div>
        )}
      </div>
    </>
  );
};

export default ProductCard;
