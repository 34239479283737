import React from "react";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";
import Button from "../button/Button";
import { Link, useNavigate } from "react-router-dom";
import freeAsYouGoBg from "../../assets/free-as-you-go/free-as-you-go-bg.png";
import catalogOne from "../../assets/free-as-you-go/catalog-1.png";
import catalogTwo from "../../assets/free-as-you-go/catalog-2.png";
import catalogThree from "../../assets/free-as-you-go/catalog-3.png";
import catalogFour from "../../assets/free-as-you-go/catalog-4.png";
import AccordionComponent from "../common/accordion/AccordionComponent";
import { faqContent } from "../../constant/faqContent";

const FreeAsYouGoSim = () => {
  const navigate = useNavigate();

  return (
    <>
      <Navbar />
      <main>
        {/* pre-footer mpove this to home  */}
        <div className="bg-[#5A949A]/70 text-white flex justify-center items-center py-2">
          <p className="text-xs spa">
            Buy Any Plan Now and Get Up to{" "}
            <span className="font-semibold">40% Discount Instantly!</span>
          </p>
        </div>
        {/* Landing Page */}
        <main className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-3">
          <div className="flex flex-col justify-center items-center my-10">
            <h1 className="font-semibold text-4xl text-center mb-2">
              Free Pay As You Go SIM
            </h1>
            <p className="text-center w-[80%]">
              Enjoy total flexibility with no commitment. Order your free SIM
              today and choose great value Top-ups and Data Packs whenever you
              need them – and enjoy extra perks too.
            </p>
            <img src={freeAsYouGoBg} alt="Free as you go" />
            <Link to="/free-sim-order">
              <button
                type="button"
                className="cta-one text-lg py-2 px-8 rounded-lg my-2"
              >
                Get a FREE SIM
              </button>
            </Link>
          </div>
        </main>
        <div className="bg-[#5A949A]/10 text-white flex justify-center items-center py-10 my-10">
          <p className="text-primary font-medium text-2xl">Amazing Benefits</p>
        </div>
        <div className="mx-auto my-5 max-w-6xl px-4 sm:px-6 lg:px-8">
          <div className="flex gap-x-12 md:flex-row flex-col lg:px-10 px-0">
            <div className="w-full md:w-1/2 flex gap-12 flex-col">
              <div>
                <img
                  className="w-full bg-[#5A949A]/10 rounded-lg shadow-lg object-cover"
                  src={catalogOne}
                  alt="Inclusive roaming"
                />
                <h2 className="text-xl font-semibold my-2">
                  Inclusive roaming
                </h2>
                <p className="mb-2">
                  Stay connected to the people you love by using your data,
                  minutes, and texts at no extra cost in 71 destinations.
                </p>
              </div>
              <div>
                <img
                  className="w-full bg-[#5A949A]/10 rounded-lg shadow-md object-cover"
                  src={catalogTwo}
                  alt="Three"
                />
                <h2 className="text-xl font-semibold my-2">Three</h2>
                <p className="mb-2">
                  Enjoy exclusive offers from the brands you love, like Uber
                  Eats and Cineworld. There’s more to life with Three.
                </p>
              </div>
            </div>
            <div className="mt-0 md:mt-40 w-full md:w-1/2 flex gap-12 flex-col">
              <div>
                <img
                  className="w-full bg-[#5A949A]/10 rounded-lg shadow-lg"
                  src={catalogThree}
                  alt="5G Ready"
                />
                <h2 className="text-xl font-semibold my-2">5G Ready</h2>
                <p className="mb-2">
                  All our Pay As You Go SIMs get access to our 5G network at no
                  extra cost.
                </p>
              </div>
              <div>
                <img
                  className="w-full bg-[#5A949A]/10 rounded-lg shadow-lg"
                  src={catalogFour}
                  alt="No Commitments"
                />
                <h2 className="text-xl font-semibold my-2">No Commitments</h2>
                <p className="mb-2">
                  With Pay As You Go you there's no contract and no commitment.
                  You can cancel any time and it's completely hassle-free.
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-col my-16 lg:px-10 px-0">
            <h2 className="text-primary text-3xl font-semibold my-2 text-center mb-6">
              Why Choose Three?
            </h2>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-x-6 gap-y-10">
              <div className="bg-[#5A949A]/10 rounded-lg shadow-md px-5 py-3">
                <h2 className="text-xl font-medium leading-none my-2">
                  The UK’s Fastest 5G Network
                </h2>
                <p className="mb-2 text-sm">
                  Share your snaps in a flash, download albums in seconds, and
                  wave goodbye to buffering. Ookla has named us the UK’s Fastest
                  5G Network.
                </p>
              </div>
              <div className="bg-[#5A949A]/10 rounded-lg shadow-md px-5 py-3">
                <h2 className="text-xl font-medium leading-none my-2">
                  99% UK Coverage
                </h2>
                <p className="mb-2 text-sm">
                  Our 4G network covers 99% of the UK population (outdoor
                  coverage). And our 5G network coverage is growing day by day.
                  So you can rely on us to keep you connected.
                </p>
              </div>
              <div className="bg-[#5A949A]/10 rounded-lg shadow-md px-5 py-3">
                <h2 className="text-xl font-medium leading-none my-2">
                  Switching to Three
                </h2>
                <p className="mb-2 text-sm">
                  Moving your number over to us is as simple as sending a text.
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-col my-16 lg:px-10 px-0">
            <h2 className="text-primary text-3xl font-semibold my-2 text-center mb-6">
              Frequently Asked Questions
            </h2>
            <AccordionComponent data={faqContent} />
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default FreeAsYouGoSim;
