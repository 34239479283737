import React, { useEffect, useState } from "react";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";
import useScrollToTop from "../customHooks/useScrollToTop";
import DashboardService from "../../services/DashboardService";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from 'jwt-decode';

const Dashboard = () => {
  useScrollToTop();

  const [errors, setErrors] = useState();
  const [dashboardDetails, setDashboardDetails] = useState();
  const [startDate, setStartDate] = useState(""); // State for start date
  const [endDate, setEndDate] = useState(""); // State for end date

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  };

  const navigate = useNavigate();

  useEffect(() => {
    const today = new Date();

    // Set endDate to today's date
    const formattedEndDate = formatDate(today);

    // Set startDate to the 1st of the current month
    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const formattedStartDate = formatDate(startOfMonth);

    setStartDate(formattedStartDate);
    setEndDate(formattedEndDate);

    fetchData(formattedStartDate, formattedEndDate);
  }, []);

  // useEffect(async () => {

  const fetchData = async (startDate, endDate) => {
    setErrors("");

    if (startDate > endDate) {
      setErrors((prev) => ({
        ...prev,
        orderFetch: "Please Select valid Date",
      }));
      return;
    }
    const token = localStorage.getItem("accesstoken");
    const tokenData = jwtDecode(token);
    const exp = tokenData.exp;
    const currentTime = Math.floor(Date.now() / 1000);
    if (exp < currentTime) {
      //alert("Session Expired");
      localStorage.removeItem("token");
      localStorage.removeItem("accesstoken");
      localStorage.removeItem("email");
      localStorage.removeItem("emailId");
      localStorage.removeItem("name");
      localStorage.removeItem("username");
      localStorage.removeItem("id");
      localStorage.removeItem("userId");
      localStorage.removeItem("isVerified");
      localStorage.removeItem("roleId");
      localStorage.removeItem("loggedIn");
      localStorage.removeItem("reffralCode");

      localStorage.removeItem("dealerId");
      localStorage.removeItem("dealerFirstName");
      localStorage.removeItem("dealerLastName");
      localStorage.removeItem("dealerEmailId");
      localStorage.removeItem("emailId");
      localStorage.removeItem("dealerSignup");
      localStorage.removeItem("accesstoken");
      localStorage.removeItem("username");
      localStorage.removeItem("dealerUsername");
      localStorage.removeItem("dealerPassword");
      localStorage.removeItem("dealerAccesstoken");
      localStorage.removeItem("verifiedCount");
      localStorage.removeItem("name");
      navigate("/login");
      return;
    }
    try {
      const response = await DashboardService.getDashboardDetails(
        startDate,
        endDate
      );
      // .then((response) => {
      if (response.status == 200) {
        setDashboardDetails(response.data);
        // console.log("response=>",response.data)
      }
      // })
    } catch (error) {
      if (error.message === "Network Error") {
        setErrors();
      } else {
        switch (error.response ? error.response.status : "") {
          case 500:
            // console.log("Error: ", error.response.data.failedReason);
            setErrors();
            break;

          default:
            setErrors("Unknown error occurred. Please try again later.");
            break;
        }
      }
    }
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    if (name === "startDate") setStartDate(value);
    if (name === "endDate") setEndDate(value);
  };

  return (
    <>
      <Navbar />
      {errors?.orderFetch && (
        <p className="error-text text-center">{errors.orderFetch}</p>
      )}
      <main className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 min-h-screen">
        <h1 className="text-2xl font-semibold tracking-tight mb-6 mt-4 ml-20 flex items-center space-x-4">
          Dashboard
          <input
            type="date"
            name="startDate"
            value={startDate}
            formatDate
            onChange={handleDateChange}
            className="ml-8"
          />
          <input
            type="date"
            name="endDate"
            value={endDate}
            onChange={handleDateChange}
            className="ml-4"
          />
          <button
            onClick={() => fetchData(startDate, endDate)}
            className="cta-one px-1 py-0.6 w-1/20 rounded-lg"
          >
            Go
          </button>
        </h1>
        {dashboardDetails && (
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-x-4 gap-y-6 max-w-7xl lg:max-w-5xl mx-auto">
            <div class="flex flex-col border custom-border-50 rounded-lg p-4">
              <h2 className="text-lg font-medium tracking-tight">
                Referral Count
              </h2>
              <div>
                <h1 className="text-4xl font-medium tracking-tight mt-4">
                  {Number(dashboardDetails.totalSignUps).toLocaleString(
                    "en-US"
                  )}
                </h1>
              </div>
            </div>
            <div class="flex flex-col border custom-border-50 rounded-lg p-4">
              <h2 className="text-lg font-medium tracking-tight">
                Order Count
              </h2>
              <h1
                onClick={() => navigate("/myorder")}
                className="text-4xl font-medium tracking-tight mt-4"
              >
                {Number(dashboardDetails.successfulConversions).toLocaleString(
                  "en-US"
                )}
              </h1>
            </div>
            <div class="flex flex-col border custom-border-50 rounded-lg p-4">
              <h2 className="text-lg font-medium tracking-tight">
                Pending Referrals
              </h2>
              <h1 className="text-4xl font-medium tracking-tight mt-4">
                {Number(dashboardDetails.pendingReferrals).toLocaleString(
                  "en-US"
                )}
              </h1>
            </div>
            <div class="flex flex-col border custom-border-50 rounded-lg p-4">
              <h2 className="text-lg font-medium tracking-tight">
                Referral Amount
              </h2>
              <h1 className="text-4xl font-medium tracking-tight mt-4">
                £{" "}
                {Number(dashboardDetails.earningsFromSignUp).toLocaleString(
                  "en-US"
                )}
              </h1>
            </div>
            <div class="flex flex-col border custom-border-50 rounded-lg p-4">
              <h2 className="text-lg font-medium tracking-tight">
                Order Commission
              </h2>
              <h1 className="text-4xl font-medium tracking-tight mt-4">
                £{" "}
                {Number(dashboardDetails.earningsFromConversion).toLocaleString(
                  "en-US"
                )}
              </h1>
            </div>
            <div class="flex flex-col border custom-border-50 rounded-lg p-4">
              <h2 className="text-lg font-medium tracking-tight">
                Total Earnings
              </h2>
              <h1 className="text-4xl font-medium tracking-tight mt-4">
                £{" "}
                {Number(dashboardDetails.totalEarnings).toLocaleString("en-US")}
              </h1>
            </div>
          </div>
        )}
      </main>
      <Footer />
    </>
  );
};

export default Dashboard;
