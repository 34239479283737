import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import logo from "../../assets/logo.png";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/solid";
import { Transition } from "@headlessui/react";
import useScrollToTop from "../customHooks/useScrollToTop";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import LoginService from "../../services/LoginService";
import ForgotPasswordService from "../../services/ForgotPasswordService";
import ModalComponent from "../modalComponent/ModalComponent";
import { data } from "autoprefixer";
import Button from "../button/Button";
import { handleLogIn } from "../navbar/navbarSlice";
import VerificationService from "../../services/VerificationService";
import Loader from "../loader/Loader";
import { useDispatch } from "react-redux";
import { addUser } from "./userSlice";

export default function Login() {
  useScrollToTop();
  const [toggleIndividualPassword, setToggleIndividualPassword] =
    useState(false);
  const [toggleBusinessPassword, setToggleBusinessPassword] = useState(false);
  const [accountType, setAccountType] = useState(1);
  const [firstAcc, setFirstAcc] = useState(true);
  const [secondAcc, setSecondAcc] = useState(false);
  const [pMessaage, setPMessaage] = useState();
  const [message, setMessage] = useState();

  const [loginEmailId, setLoginEmailId] = useState("");
  const [pwd, setPwd] = useState("");
  const { id } = useParams();
  const [loginError, setLoginError] = useState();
  const [dealerLoginError, setDealerLoginError] = useState();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [userName, setUserName] = useState("");
  const [userPwd, setUserPwd] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [dealerLogin, setDealerLogin] = useState({
    email: "",
    password: "",
    isCustomer: "",
  });
  const dispatch = useDispatch();
  useEffect(() => {
    setDealerLogin({
      email: userName,
      password: userPwd,
      customer: false,
    });
  }, [userName, userPwd, false]);

  const [userlogin, setUserLogin] = useState({
    email: "",
    password: "",
    isCustomer: "",
  });
  useEffect(() => {
    setUserLogin({
      email: loginEmailId,
      password: pwd,
      customer: true,
    });
  }, [loginEmailId, pwd, true]);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const {
    register: registerDealer,
    handleSubmit: handleSubmitDealer,
    formState: { errors: errorsDealer },
  } = useForm();
  const handleAccountType = (type) => {
    if (type === 1) {
      setAccountType(1);
      setFirstAcc(true);
      setSecondAcc(false);
    } else if (type === 2) {
      setAccountType(2);
      setFirstAcc(false);
      setSecondAcc(true);
    } else {
    }
  };

  const loginClicked = (data) => {
    const loginCred = firstAcc
      ? { ...data, customer: true }
      : secondAcc
      ? { ...data, customer: false }
      : null;
    //console.log("loginCred--->", loginCred);
    setLoader(true);
    LoginService.loginUser(loginCred)
      .then((response) => {
        setLoader(false);
        // //console.log("response----", response.data);
        if (response.status === 200) {
          localStorage.setItem("accesstoken", response.data.accesstoken);
          localStorage.setItem("emailId", response.data.email);
          localStorage.setItem("name", response.data.name);
          localStorage.setItem("username", response.data.username);
          localStorage.setItem("userId", response.data.userId);
          localStorage.setItem("isVerified", response.data.verified);
          localStorage.setItem("roleId", response.data.roleId);
          localStorage.setItem("loggedIn", true);
          dispatch(
            addUser({
              accesstoken: response.data.accesstoken,
              emailId: response.data.email,
              name: response.data.name,
              username: response.data.id,
              userId: response.data.userId,
              isVerified: response.data.verified,
              roleId: response.data.roleId,
            })
          );
          localStorage.setItem("reffralCode", response.data.reffralCode);
          dispatch(handleLogIn());
          setPMessaage("login successful");
          navigate("/");
          // }
        } else {
          setPMessaage("");
        }
      })
      .catch((error) => {
        setLoader(false);
        if (error.message === "Network Error") {
          loginCred.customer === true
            ? setLoginError("Network Error")
            : setDealerLoginError("Network Error");
        } else {
          switch (error.response ? error.response.status : "") {
            case 401:
              //console.log("error.response", error.response);
              loginCred.customer === true
                ? setLoginError(error.response.data.failedReason)
                : setDealerLoginError(error.response.data.failedReason);
              // setErrorCode(401)
              break;
            case 403:
              //console.log("error.response1111", error.response);
              loginCred.customer === true
                ? setLoginError(error.response.data.failedReason)
                : setDealerLoginError(error.response.data.failedReason);
              break;
            case 500:
              //console.log("error.response", error.response);
              loginCred.customer === true
                ? setLoginError(error.response.data.failedReason)
                : setDealerLoginError(error.response.data.failedReason);
              break;
            case 404:
              //console.log("error.response", error.response);
              loginCred.customer === true
                ? setLoginError(error.response.data.failedReason)
                : setDealerLoginError(error.response.data.failedReason);
              break;
            case 406:
              //console.log("error.response", error.response);
              loginCred.customer === true
                ? setLoginError(error.response.data.failedReason)
                : setDealerLoginError(error.response.data.failedReason);
              break;
            case 412:
              //console.log("error.response", error.response);
              loginCred.customer === true
                ? setLoginError(error.response.data.failedReason)
                : setDealerLoginError(error.response.data.failedReason);
              break;
            default:
              setLoginError("Unknown Error");
              loginCred.customer === true
                ? setLoginError(error.response.data.failedReason)
                : setDealerLoginError(error.response.data.failedReason);
              break;
          }
        }
      });
  };

  useEffect(() => {
    if (id !== undefined && id !== null && id !== "") {
      setShowLoader(true);
      //console.log("idd====", id);
      VerificationService.doVerification(id)
        .then((response) => {
          // //console.log("response=====", response.data);
          // //console.log("response tttt=====", response.status);

          if (response.status === 200) {
            //console.log("inside customer login=====", response);
            localStorage.setItem("accesstoken", response.data.token);
            localStorage.setItem("emailId", response.data.email);
            localStorage.setItem("name", response.data.name);
            localStorage.setItem("username", response.data.id);
            localStorage.setItem("dealerSignup", response.data.dealerSignup);
            localStorage.setItem("reffralCode", response.data.reffralCode);

            setPMessaage("login successful");
            navigate("/");
          } else if (response.status === 202) {
            setShowLoader(false);
            localStorage.setItem("dealerAccesstoken", response.data.token);
            localStorage.setItem("dealerEmailId", response.data.email);
            localStorage.setItem("dealerFirstName", response.data.firstName);
            localStorage.setItem("dealerLastName", response.data.lastName);
            localStorage.setItem("dealerId", response.data.dealerId);
            localStorage.setItem("dealerPassword", response.data.password);
            localStorage.setItem("dealerUsername", response.data.username);
            localStorage.setItem("dealerSignup", response.data.dealerSignup);
          } else {
            setMessage("");
          }
        })
        .catch((error) => {
          if (error.message === "Network Error") {
            setMessage("Network Error");
          } else {
            //console.log("errrrroorrr====", error.response);

            switch (error.response ? error.response.status : "") {
              case 401:
                setMessage(error.response.data.failedReason);
                break;
              case 403:
                setMessage(error.response.data.failedReason);
                break;
              case 500:
                setMessage(error.response.data.failedReason);
                break;
              case 404:
                setMessage(error.response.data.failedReason);
                break;
              case 406:
                setMessage(error.response.data.failedReason);
                break;
              case 412:
                setMessage(error.response.data.failedReason);
                break;
              default:
                setMessage("unknown Error");
                break;
            }
          }
        });
      // .finally(() => {
      //   setIsLoading(false);
      // });
    }
  }, []);

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-20 lg:px-8">
        <div className="mx-auto w-full max-w-sm md:max-w-md">
          <Link to="/">
            <img className="mx-auto h-[80px] w-auto" src={logo} alt="GKT" />
          </Link>
          <div className="flex flex-col justify-center items-center">
            <h2 className="text-center text-2xl font-semibold leading-9 tracking-tight primary-text">
              Welcome Back!
            </h2>
            <p className="text-lg font-medium mt-4">Choose Your Account Type</p>
          </div>
          {/* Account tabs */}
          <div className="border custom-border-full flex mt-2 mb-4 rounded-md p-[1px]">
            <button
              onClick={() => handleAccountType(1)}
              className={`w-full h-8 text-sm rounded-md py-1 px-2 ${
                accountType === 1 ? "login-active" : ""
              }`}
            >
              Customer Login
            </button>
            <button
              onClick={() => handleAccountType(2)}
              className={`w-full h-8 text-sm rounded-md py-1 px-2 ${
                accountType === 2 ? "login-active" : ""
              }`}
            >
              Business Login
            </button>
          </div>
        </div>
        <div className="mt-4 mx-auto w-full max-w-sm md:max-w-md">
          <Transition
            show={firstAcc}
            enter="transition-all duration-300 transform"
            enterFrom="opacity-0 transform -translate-x-12"
            enterTo="opacity-100 transform translate-x-0"
            leave="transition-all duration-100 transform -translate-x-12"
            leaveFrom="opacity-100 transform translate-x-0"
            leaveTo="opacity-0 transform -translate-x-12"
          >
            <form onSubmit={handleSubmit(loginClicked)}>
              <div
                id="individual-form"
                className="space-y-6"
                // ${accountType === 2 ? "hidden" : ""}
              >
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-normal leading-6"
                  >
                    Email address*
                  </label>
                  <div className="mt-2">
                    <input
                      {...register("email", {
                        required: "Email is required",
                        pattern: {
                          value: /^\S+@\S+$/i,
                          message: "Invalid email address",
                        },
                      })}
                      // onChange={(e) => {
                      //   setLoginEmailId(e.target.value);
                      // }}
                      id="email"
                      type="email"
                      // autoComplete="email"
                      placeholder="Enter your e-mail address"
                      className="block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-30 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                    />
                    {errors.email && (
                      <p className="error-text">{errors.email.message}</p>
                    )}
                  </div>
                </div>
                <div>
                  <div className="flex items-center justify-between">
                    <label
                      htmlFor="individual-password"
                      className="block text-sm font-normal leading-6"
                    >
                      Password*
                    </label>
                    <div className="text-sm">
                      <span
                        onClick={() => navigate("/forgotPassword/individual")}
                        className="font-semibold primary-text hover:underline decoration-1"
                      >
                        Forgot password?
                      </span>
                    </div>
                  </div>
                  <div className="mt-2 relative">
                    <input
                      {...register("password", {
                        required: "Password is required",
                      })}
                      // onChange={(e) => {
                      //   setPwd(e.target.value);
                      // }}
                      id="individual-password"
                      type={toggleIndividualPassword ? "text" : "password"}
                      // autoComplete="current-password"
                      placeholder="Enter your password"
                      className="block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-30 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                      // onChange={(e) => {
                      //   if (!e.target.value) {
                      //     setLoginError("");
                      //   }
                      // }}
                    />

                    <div
                      onClick={() =>
                        setToggleIndividualPassword(!toggleIndividualPassword)
                      }
                      className="absolute top-1/2 -translate-y-1/2 right-2 w-[18px] cursor-pointer"
                    >
                      {toggleIndividualPassword ? (
                        <EyeSlashIcon />
                      ) : (
                        <EyeIcon />
                      )}
                    </div>
                  </div>
                  {errors.password && (
                    <p className="error-text">{errors.password.message}</p>
                  )}
                  {loginError && <p className="error-text">{loginError}</p>}
                </div>

                <div>
                  <button
                    // onClick={loginClicked}
                    //  type="button"
                    type="submit"
                    className="flex w-full justify-center rounded-md cta-one px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                  >
                    Sign in
                  </button>
                </div>
              </div>
            </form>
          </Transition>
          <Transition
            show={secondAcc}
            enter="transition-all duration-300 transform"
            enterFrom="opacity-0 transform translate-x-12"
            enterTo="opacity-100 transform translate-x-0"
            leave="transition-all duration-100 transform -translate-x-12"
            leaveFrom="opacity-100 transform translate-x-0"
            leaveTo="opacity-0 transform translate-x-12"
          >
            <form onSubmit={handleSubmitDealer(loginClicked)}>
              <div
                id="business-form"
                className="space-y-6"
                // ${accountType === 1 ? "hidden" : ""}
              >
                {/* <div>
                  <label
                    htmlFor="dealer-id"
                    className="block text-sm font-normal leading-6"
                  >
                    Dealer ID
                  </label>
                  <div className="mt-2">
                    <input
                      id="dealer-id"
                      type="email"
                      // autoComplete="email"
                      placeholder="Enter your dealer id"
                      required
                      className="block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-30 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                    />
                  </div>
                </div> */}
                <div>
                  <label
                    htmlFor="username"
                    className="block text-sm font-normal leading-6"
                  >
                    Email address*
                  </label>
                  <div className="mt-2">
                    <input
                      {...registerDealer("email", {
                        required: "Email is required",
                      })}
                      // onChange={(e) => {
                      //   setUserName(e.target.value);
                      // }}
                      id="email"
                      type="text"
                      // autoComplete="username"
                      placeholder="Enter your username"
                      className="block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-30 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                      // onChange={(e) => {
                      //   if (!e.target.value) {
                      //     setLoginError("");
                      //   }
                      // }}
                    />
                    {errorsDealer.email && (
                      <p className="error-text">{errorsDealer.email.message}</p>
                    )}
                  </div>
                </div>
                <div>
                  <div className="flex items-center justify-between">
                    <label
                      htmlFor="password"
                      className="block text-sm font-normal leading-6"
                    >
                      Password*
                    </label>
                    <div className="text-sm">
                      <span
                        onClick={() => navigate("/forgotPassword/business")}
                        className="font-semibold primary-text hover:underline decoration-1"
                      >
                        Forgot password?
                      </span>
                    </div>
                  </div>
                  <div className="mt-2 relative">
                    <input
                      {...registerDealer("password", {
                        required: "Password is required",
                      })}
                      // onChange={(e) => {
                      //   setUserPwd(e.target.value);
                      // }}
                      id="password"
                      name="password"
                      type={toggleBusinessPassword ? "text" : "password"}
                      // autoComplete="current-password"
                      placeholder="Enter your password"
                      className="block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-30 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                    />

                    <div
                      onClick={() =>
                        setToggleBusinessPassword(!toggleBusinessPassword)
                      }
                      className="absolute top-1/2 -translate-y-1/2 right-2 w-[18px] cursor-pointer"
                    >
                      {toggleBusinessPassword ? <EyeSlashIcon /> : <EyeIcon />}
                    </div>
                  </div>
                  {errorsDealer.password && (
                    <p className="error-text">
                      {errorsDealer.password.message}
                    </p>
                  )}
                  {dealerLoginError && (
                    <p className="error-text">{dealerLoginError}</p>
                  )}
                </div>
                <div>
                  <button
                    //  onClick={loginClicked}
                    // type="button"
                    type="submit"
                    className="flex w-full justify-center rounded-md cta-one px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                  >
                    Sign in
                  </button>
                </div>
              </div>
            </form>
          </Transition>
          <p className="mt-10 text-center text-sm ">
            <span className="text-gray-500">Don't have an account? </span>
            <Link
              to="/register"
              className="font-semibold hover:underline decoration-1 primary-text"
            >
              Sign Up
            </Link>
          </p>
        </div>
      </div>
      {loader && <Loader />}
      {/* <ModalComponent
          title="Password Reset Email Sent"
          description="An email with instructions to reset your password has been successfully sent to your registered email address."
        /> */}
    </>
  );
}
