import React from "react";
import { Transition, TransitionChild } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { closeModal } from "./modalSlice";
import { FaRegCheckCircle } from "react-icons/fa";
import { GoInfo } from "react-icons/go";
import { BiError } from "react-icons/bi";
import useScrollToTop from "../customHooks/useScrollToTop";

export default function ModalComponent({
  size,
  position,
  title,
  description,
  bottomLine,
  type,
  children,
  crossButton,
}) {
  const dispatch = useDispatch();
  const { isOpen } = useSelector((state) => state.modal);
  useScrollToTop();

  return (
    <>
      <Transition appear show={isOpen}>
        <div className="relative z-[999] focus:outline-none">
          <div className="fixed inset-0 z-10 w-screen overflow-y-auto ">
            <TransitionChild
              enter="ease-out duration-300"
              enterFrom="opacity-0 transform-[scale(95%)]"
              enterTo="opacity-100 transform-[scale(100%)]"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 transform-[scale(100%)]"
              leaveTo="opacity-0 transform-[scale(95%)]"
            >
              <div
                // onClick={() => dispatch(closeModal())}
                className={`bg-teal-800/50 flex min-h-screen ${
                  position === "top" ? "items-start" : "items-center"
                } justify-center p-4`}
              >
                <div
                  // onClick={(e) => e.stopPropagation()}
                  className={`w-full top-0 border rounded-lg ${
                    type === "error"
                      ? "border-red-300 bg-red-50"
                      : type === "success"
                      ? "border-green-300 bg-green-50"
                      : type === "info"
                      ? "border-blue-300 bg-blue-50"
                      : type === "warning"
                      ? "border-yellow-300 bg-yellow-50"
                      : type === "loading"
                      ? "bg-secondary"
                      : "bg-secondary"
                  } 0 max-w-${size} rounded-xl p-4 backdrop-blur-2xl`}
                >
                  <div
                    className={`flex flex-col justify-between items-center mb-3 ${
                      bottomLine ? "border-b-2" : ""
                    }`}
                  >
                    <div className="w-full flex justify-between items-center pb-2 relative">
                      {title && (
                        <div className="flex items-center">
                          {type && (
                            <div>
                              {type === "error" ? (
                                <BiError className="text-red-600 text-2xl font-semibold mr-2" />
                              ) : type === "success" ? (
                                <FaRegCheckCircle className="text-green-600 text-2xl font-semibold mr-2" />
                              ) : type === "info" ? (
                                <GoInfo className="text-blue-600 text-2xl font-semibold mr-2" />
                              ) : type === "warning" || "pending" ? (
                                <BiError className="text-yellow-600 text-2xl font-semibold mr-2" />
                              ) : (
                                ""
                              )}
                            </div>
                          )}
                          {/* ${
                            type === "error"
                              ? "text-red-600"
                              : type === "success"
                              ? "text-green-600"
                              : type === "info"
                              ? "text-blue-600"
                              : type === "warning"
                              ? "text-yellow-600"
                              : "bg-secondary"
                          } */}
                          <h3
                            className={`
                         
                          text-lg font-semibold`}
                          >
                            {title}
                          </h3>
                        </div>
                      )}
                      {crossButton == false ? null : (
                        <button
                          onClick={() => dispatch(closeModal(null))}
                          className="absolute right-1 top-1 inline-flex items-center gap-2 rounded-md hover:bg-slate-500/20 transition p-0"
                        >
                          <XMarkIcon className="w-5" />
                        </button>
                      )}
                      {description && (
                        <div>
                          <p>{description}</p>
                        </div>
                      )}
                    </div>
                  </div>
                  {children}
                </div>
              </div>
            </TransitionChild>
          </div>
        </div>
      </Transition>
    </>
  );
}
