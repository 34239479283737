import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.png";
import ForgotPasswordService from "../../services/ForgotPasswordService";
import { useNavigate } from "react-router-dom";
import ModalComponent from "../modalComponent/ModalComponent";
import { useDispatch } from "react-redux";
import { closeModal, openModal } from "../modalComponent/modalSlice";
import Button from "../button/Button";
import loadingWhiteGif from "../../assets/loading-white.gif";
import ChangePasswordService from "../../services/ChangePasswordService";
import { useForm } from "react-hook-form";
import Loader from "../loader/Loader";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/solid";
import { jwtDecode } from 'jwt-decode';

const ChangePassword = () => {
  const userId = localStorage.getItem("userId");
  //console.log("userId---->", userId);
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [forgotPassState, setForgotPassState] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [curPwd, setCurPwd] = useState("");
  const [newPwd, setNewPwd] = useState("");
  const [reNewPwd, setReNewPwd] = useState("");
  const [userName, setUserName] = useState("");
  const [userPwd, setUserPwd] = useState("");
  const [pMessaage, setPMessaage] = useState();
  const [message, setMessage] = useState();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [loader, setLoader] = useState(false);
  const [updatePassword, setUpdatePassword] = useState({
    oldPassword: "",
    password: "",
    confirmPassword: "",
    userId: "",
  });
  const [currentPassword, setCurrentPassword] = useState(false);
  const [newPassword, setNewPassword] = useState(false);
  const [rePassword, setRePassword] = useState(false);

  useEffect(() => {
    setUpdatePassword({
      oldPassword: curPwd,
      password: newPwd,
      confirmPassword: reNewPwd,
      userId: userId,
    });
  }, [curPwd, newPwd, reNewPwd, 1]);
  const updatePasswordClick = (data) => {
    const token = localStorage.getItem("accesstoken");
    const tokenData = jwtDecode(token);
    const exp = tokenData.exp;
    const currentTime = Math.floor(Date.now() / 1000);
    if (exp < currentTime) {
      //alert("Session Expired");
      localStorage.removeItem("token");
      localStorage.removeItem("accesstoken");
      localStorage.removeItem("email");
      localStorage.removeItem("emailId");
      localStorage.removeItem("name");
      localStorage.removeItem("username");
      localStorage.removeItem("id");
      localStorage.removeItem("userId");
      localStorage.removeItem("isVerified");
      localStorage.removeItem("roleId");
      localStorage.removeItem("loggedIn");
      localStorage.removeItem("reffralCode");

      localStorage.removeItem("dealerId");
      localStorage.removeItem("dealerFirstName");
      localStorage.removeItem("dealerLastName");
      localStorage.removeItem("dealerEmailId");
      localStorage.removeItem("emailId");
      localStorage.removeItem("dealerSignup");
      localStorage.removeItem("accesstoken");
      localStorage.removeItem("username");
      localStorage.removeItem("dealerUsername");
      localStorage.removeItem("dealerPassword");
      localStorage.removeItem("dealerAccesstoken");
      localStorage.removeItem("verifiedCount");
      localStorage.removeItem("name");
      navigate("/login");
      return;
    }
    const updatePassword = {
      oldPassword: data.curPwd,
      password: data.newPwd,
      confirmPassword: data.reNewPwd
    };
    setLoader(true);
    ChangePasswordService.updatePassword(updatePassword)
      .then((response) => {
        setLoader(false);
        if (response.status === 200) {
          //console.log("update succesfully");
          //   localStorage.setItem("accesstoken", response.data.token);
          //   localStorage.setItem("emailId", response.data.email);
          //   localStorage.setItem("name", response.data.name);
          //   localStorage.setItem("username", response.data.id);
          dispatch(openModal());
          setPMessaage("login successful");
          //   navigate("/login");
          // }
        } else {
          setPMessaage("");
        }
      })
      .catch((error) => {
        setLoader(false);
        //console.log("error", error);
        if (error.message === "Network Error") {
          setMessage("Network Error");
        } else {
          switch (error.response ? error.response.status : "") {
            case 401:
            case 403:
            case 500:
            case 404:
            case 406:
            case 412:
              setMessage(error.response.data.failedReason);
              break;
            default:
              //console.log("error.response", error.response);
              setMessage("unknown Error");
              break;
          }
        }
      });
  };
  const validatePasswords = (value) => {
    return value === watch("newPwd") || "Passwords do not match";
  };

  const passwordValidationRules = {
    required: "Password is required",
    minLength: { value: 8, message: "Password must be at least 8 characters" },
    pattern: {
      value:
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      message:
        "Password must include 1 uppercase, 1 lowercase, 1 number, and 1 symbol",
    },
    validate: (value) => {
      const curPwd = watch("curPwd");
      return value !== curPwd || "New password cannot be the same as the current password";
    },
  };
  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-20 lg:px-8">
      <div className="mx-auto w-full max-w-sm md:max-w-md">
        <Link to="/">
          <img className="mx-auto h-[60px] w-auto" src={logo} alt="GKT" />
        </Link>
        <div className="flex flex-col justify-center items-center">
          <h2 className="text-center text-2xl font-semibold leading-9 tracking-tight primary-text">
            Change Password
          </h2>
          <p className="text-lg text-center font-medium mt-4">
            {/* Enter your Current password */}
          </p>
        </div>
      </div>
      <div className="mt-4 mx-auto w-full max-w-sm md:max-w-md">
        <form
          id="forgot-password-form"
          className="space-y-6"
          onSubmit={handleSubmit(updatePasswordClick)}
        >
          <div id="individual-form" className="space-y-6">
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-normal leading-6"
              >
                Current Password*
              </label>
              <div className="mt-2 relative">
                <input
                  // onChange={(e) => setCurPwd(e.target.value)}
                  {...register("curPwd", { required: "Password is required" })}
                  id="curPwd"
                  type={currentPassword ? "text" : "password"}
                  placeholder="Enter Your Current Password"
                  className="block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-30 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                />
                <div
                  onClick={() => setCurrentPassword(!currentPassword)}
                  className="absolute top-1/2 -translate-y-1/2 right-2 w-[18px] cursor-pointer"
                >
                  {currentPassword ? <EyeSlashIcon /> : <EyeIcon />}
                </div>
              </div>
              {errors.curPwd && (
              <p className="error-text">{errors.curPwd.message}</p>
            )}
            </div>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-normal leading-6"
              >
                New Password*
              </label>
              <div className="mt-2 relative">
                <input
                  {...register("newPwd", passwordValidationRules)}
                  // onChange={(e) => setNewPwd(e.target.value)}
                  id="newPwd"
                  type={newPassword ? "text" : "password"}
                  placeholder="Enter your New Password"
                  className="block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-30 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                />
                <div
                  onClick={() => setNewPassword(!newPassword)}
                  className="absolute top-1/2 -translate-y-1/2 right-2 w-[18px] cursor-pointer"
                >
                  {newPassword ? <EyeSlashIcon /> : <EyeIcon />}
                </div>
              </div>
              {errors.newPwd && (
              <p className="error-text">{errors.newPwd.message}</p>
            )}
            </div>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-normal leading-6"
              >
                Re-Type New Password*
              </label>
              <div className="mt-2 relative">
                <input
                  {...register("reNewPwd", {
                    required: "Password is required",
                    validate: validatePasswords,
                  })}
                  // onChange={(e) => setReNewPwd(e.target.value)}
                  id="reNewPwd"
                  type={rePassword ? "text" : "password"}
                  placeholder=" Re-Type New Password"
                  className="block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-30 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                />
                <div
                  onClick={() => setRePassword(!rePassword)}
                  className="absolute top-1/2 -translate-y-1/2 right-2 w-[18px] cursor-pointer"
                >
                  {rePassword ? <EyeSlashIcon /> : <EyeIcon />}
                </div>
              </div>
              {errors.reNewPwd && (
              <p className="error-text">{errors.reNewPwd.message}</p>
            )}
            </div>        
            <div>
              <button
                // onClick={updatePasswordClick}
                // type="button"
                type="submit"
                className="flex w-full justify-center rounded-md cta-one px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <img
                    width={20}
                    height={20}
                    src={loadingWhiteGif}
                    alt="Loading..."
                  />
                ) : (
                  "Update Password"
                )}
              </button>
            </div>
            {message && <p className="error-text text-center">{message}</p>}
          </div>
        </form>
      </div>
      {/* <p className="mt-6 text-center text-sm ">
        <span className="text-gray-500">Already have an Account? </span>
        <Link
          to="/login"
          className="font-semibold hover:underline decoration-1 primary-text"
        >
          Login
        </Link>
      </p> */}
      <ModalComponent
        size="md"
        type="success"
        position="center"
        title="Password Updated"
        description=""
        bottomLine={true}
      >
        <div>
          <p>
            your password has been successfully Updated
            <br />
            {/* Please proceed with the "Reset Button" */}
          </p>
          <Button
            handleClick={() => {
              navigate("/");
              dispatch(closeModal());
            }}
            title="Okay"
            type="button"
            style="cta-two mt-4"
          />
        </div>
      </ModalComponent>
      {loader && <Loader />}
    </div>
  );
};

export default ChangePassword;
