import React, { useEffect, useState } from "react";
import Navbar from "../navbar/Navbar";
import DataTable, { createTheme } from "react-data-table-component";
import Loader from "../loader/Loader";
import OrderService from "../../services/OrderService";
import OrderCard from "./OrderCard";
import { LuSearchX } from "react-icons/lu";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

const MyOrder = () => {
  //states
  const [rows, setRows] = useState([]);
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const userId = localStorage.getItem("userId") || "";
  const userRoleId = localStorage.getItem("roleId") || "";
  const [errors, setErrors] = useState({});
  const [startDate, setStartDate] = useState(""); // State for start date
  const [endDate, setEndDate] = useState(""); // State for end date
  const navigate = useNavigate();

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  };

  //initialize
  var columns = [];
  if (userRoleId != null && userRoleId === "ROLE_DEALER") {
    columns = [
      {
        name: "Order Date",
        selector: (row) => row.createdDate,
        sortable: true,
        wrap: true,
        minWidth: "200px",
      },
      {
        name: "Order Number",
        selector: (row) => row.orderNumber,
        sortable: true,
        wrap: true,
        minWidth: "100px",
      },
      {
        name: "Order Amount",
        selector: (row) => row.amount,
        sortable: true,
        wrap: true,
        minWidth: "100px",
      },
      {
        name: "Order Status",
        selector: (row) => row.orderStatusDescription,
        sortable: true,
        wrap: true,
        minWidth: "100px",
      },
      {
        name: "Order Type",
        selector: (row) => row.orderTypeDescription,
        sortable: true,
        wrap: true,
        minWidth: "100px",
      },
      {
        name: "Customer Name",
        selector: (row) => row.username,
        sortable: true,
        wrap: true,
        minWidth: "200px",
      },
      {
        name: "Email ID",
        selector: (row) => row.email,
        sortable: true,
        wrap: true,
        minWidth: "200px",
      },
      {
        name: "Plan Name",
        selector: (row) => row.tariffName,
        sortable: true,
        wrap: true,
        minWidth: "200px",
      },
      {
        name: "Data",
        selector: (row) => row.planData,
        sortable: true,
        wrap: true,
        minWidth: "100px",
      },
      {
        name: "Minutes",
        selector: (row) => row.noOfMinutes,
        sortable: true,
        wrap: true,
        minWidth: "100px",
      },
      {
        name: "Text",
        selector: (row) => row.noOfText,
        sortable: true,
        wrap: true,
        minWidth: "100px",
      },
      {
        name: "Validity",
        selector: (row) => row.contractTerm,
        sortable: true,
        wrap: true,
        minWidth: "100px",
      },
      {
        name: "Network",
        selector: (row) => row.networkName,
        sortable: true,
        wrap: true,
        minWidth: "100px",
      },
      {
        name: "Mobile Number",
        selector: (row) => row.lockMsisdn,
        sortable: true,
        wrap: true,
        minWidth: "200px",
      },
      {
        name: "ICCID",
        selector: (row) => row.iccid,
        sortable: true,
        wrap: true,
        minWidth: "200px",
      },
      {
        name: "Port In?",
        selector: (row) => row.mobileNoSelectionId,
        sortable: true,
        wrap: true,
        minWidth: "200px",
      },
      {
        name: "Signup Commission",
        selector: (row) => row.signupCommission,
        sortable: true,
        wrap: true,
        minWidth: "200px",
      },
      {
        name: "Order Commission",
        selector: (row) => row.orderCommission,
        sortable: true,
        wrap: true,
        minWidth: "200px",
      },
      {
        name: "Total Commission",
        selector: (row) => row.totalCommission,
        sortable: true,
        wrap: true,
        minWidth: "200px",
      },
    ];
  } else {
    columns = [
      {
        name: "Order Date",
        selector: (row) => row.createdDate,
        sortable: true,
        wrap: true,
        minWidth: "200px",
      },
      {
        name: "Order Number",
        selector: (row) => row.orderNumber,
        sortable: true,
        wrap: true,
        minWidth: "100px",
      },
      {
        name: "Order Amount",
        selector: (row) => row.amount,
        sortable: true,
        wrap: true,
        minWidth: "100px",
      },
      {
        name: "Order Status",
        selector: (row) => row.orderStatusDescription,
        sortable: true,
        wrap: true,
        minWidth: "100px",
      },
      {
        name: "Order Type",
        selector: (row) => row.orderTypeDescription,
        sortable: true,
        wrap: true,
        minWidth: "100px",
      },
      {
        name: "Plan Name",
        selector: (row) => row.tariffName,
        sortable: true,
        wrap: true,
        minWidth: "200px",
      },
      {
        name: "Data",
        selector: (row) => row.planData,
        sortable: true,
        wrap: true,
        minWidth: "100px",
      },
      {
        name: "Minutes",
        selector: (row) => row.noOfMinutes,
        sortable: true,
        wrap: true,
        minWidth: "100px",
      },
      {
        name: "Text",
        selector: (row) => row.noOfText,
        sortable: true,
        wrap: true,
        minWidth: "100px",
      },
      {
        name: "Validity",
        selector: (row) => row.contractTerm,
        sortable: true,
        wrap: true,
        minWidth: "100px",
      },
      {
        name: "Network",
        selector: (row) => row.networkName,
        sortable: true,
        wrap: true,
        minWidth: "100px",
      },
      {
        name: "Mobile Number",
        selector: (row) => row.lockMsisdn,
        sortable: true,
        wrap: true,
        minWidth: "200px",
      },
      {
        name: "Port In?",
        selector: (row) => row.mobileNoSelectionId,
        sortable: true,
        wrap: true,
        minWidth: "200px",
      },
    ];
  }
  const tableCustomStyles = {
    headCells: {
      style: {
        border: "1px solid black",
        fontSize: "20px",
        fontWeight: "bold",
        paddingLeft: "0 8px",
        justifyContent: "center",
        backgroundColor: "#FFA500",
      },
    },
    rows: {
      style: {
        minHeight: "72px", // override the row height
      },
    },
    cells: {
      style: {
        border: "1px solid black",
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };
  createTheme(
    "solarized",
    {
      text: {
        primary: "#268bd2",
        secondary: "#2aa198",
      },
      background: {
        default: "#002b36",
      },
      context: {
        background: "#cb4b16",
        text: "#FFFFFF",
      },
      divider: {
        default: "#073642",
      },
      action: {
        button: "rgba(0,0,0,.54)",
        hover: "rgba(0,0,0,.08)",
        disabled: "rgba(0,0,0,.12)",
      },
    },
    "dark"
  );

  // //effects
  //   const fetchData = async () => {
  //   setLoader(true);

  //   await OrderService.getOrder(userId, startDate, endDate)
  //     .then((response) => {
  //       if (response.status == 200) {
  //         const list = response.data;
  //         console.log("list", list);

  //         setData([]);
  //         setRows([]);
  //         if (list != null && list.length > 0) {
  //           let tempList = [];
  //           list.forEach((order) => {
  //             tempList.push({
  //               id: order.orderId,
  //               createdDate:
  //                 order.createdDate &&
  //                 order.createdDate
  //                   .toString()
  //                   .substring(0, 19)
  //                   .replace("T", " ") + " GMT",
  //               orderNumber: order.orderNumber,
  //               amount: "£" + order.amount,
  //               orderStatusDescription:
  //                 order.orderStatus.orderStatusDescription,
  //               orderTypeDescription: order.orderType.orderTypeDescription,
  //               username: order.creditCheck.customer.name,
  //               email: order.creditCheck.customer.email,
  //               tariffName: order.creditCheck.plan.tariffName,
  //               planData:
  //                 order.creditCheck.plan.data === 30
  //                   ? "Unlimited"
  //                   : `${order.creditCheck.plan.data} GB`,
  //               noOfMinutes:
  //                 order.creditCheck.plan.noOfMinutes === -1
  //                   ? "Unlimited"
  //                   : order.creditCheck.plan.noOfMinutes,
  //               noOfText:
  //                 order.creditCheck.plan.noOfText === -1
  //                   ? "Unlimited"
  //                   : order.creditCheck.plan.noOfText,
  //               contractTerm: order.creditCheck.plan.contractTerm + " Months",
  //               networkId: order.creditCheck.plan.network.networkId,
  //               networkName: order.creditCheck.plan.network.networkName,
  //               lockMsisdn:
  //                 order.creditCheck.lockMsisdn &&
  //                 "+" + order.creditCheck.lockMsisdn,
  //               iccid: order.creditCheck.iccid,
  //               signupCommission: "£" +order.signupCommission,
  //               orderCommission: "£" +order.orderCommission,
  //               totalCommission: "£" +order.totalCommission,

  //             });
  //           });

  //           setData(tempList);
  //           setRows(tempList);
  //         }
  //         setLoader(false);
  //       }
  //     })
  //     .catch((error) => {
  //       if (error.message === "Network Error") {
  //         setErrors((prev) => ({
  //           ...prev,
  //           orderFetch: "Network error occurred. Please try again later.",
  //         }));
  //         setLoader(false);
  //       } else {
  //         switch (error.response ? error.response.status : "") {
  //           case 401:
  //             setErrors((prev) => ({
  //               ...prev,
  //               orderFetch: error.response.data.failedReason,
  //             }));
  //             break;
  //           case 403:
  //             setErrors((prev) => ({
  //               ...prev,
  //               orderFetch: error.response.data.failedReason,
  //             }));
  //             break;
  //           case 500:
  //             console.log("Error: ", error.response.data.failedReason);
  //             setErrors((prev) => ({
  //               ...prev,
  //               orderFetch:
  //                 "Internal server error occurred. Please try again later.",
  //             }));
  //             break;
  //           case 404:
  //             setErrors((prev) => ({
  //               ...prev,
  //               orderFetch: error.response.data.failedReason,
  //             }));
  //             break;
  //           case 406:
  //             setErrors((prev) => ({
  //               ...prev,
  //               orderFetch: error.response.data.failedReason,
  //             }));
  //             break;
  //           case 412:
  //             setErrors((prev) => ({
  //               ...prev,
  //               orderFetch: error.response.data.failedReason,
  //             }));
  //             break;
  //           default:
  //             setErrors((prev) => ({
  //               ...prev,
  //               orderFetch: "Unknown error occurred. Please try again later.",
  //             }));
  //             break;
  //         }
  //         setLoader(false);
  //       }
  //     });

  //   setLoader(false);
  //   fetchData();
  // // }, []);
  // };

  // },[startDate, endDate, userId]);

  // useEffect(() => {
  //     const today = new Date();
  //     const formattedDate = formatDate(today);
  //     setStartDate(formattedDate);
  //     setEndDate(formattedDate);
  //   }, []);
  useEffect(() => {
    const today = new Date();

    // Set endDate to today's date
    const formattedEndDate = formatDate(today);

    // Set startDate to the 1st of the current month
    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const formattedStartDate = formatDate(startOfMonth);

    setStartDate(formattedStartDate);
    setEndDate(formattedEndDate);

    fetchData(formattedStartDate, formattedEndDate);
  }, []);

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    if (name === "startDate") setStartDate(value);
    if (name === "endDate") setEndDate(value);
  };

  // Fetch data based on date range
  const fetchData = async (startDate, endDate) => {
    if (startDate > endDate) {
      setErrors((prev) => ({
        ...prev,
        orderFetch: "Please Select valid Date",
      }));
      return;
    }
    const token = localStorage.getItem("accesstoken");
    const tokenData = jwtDecode(token);
    const exp = tokenData.exp;
    const currentTime = Math.floor(Date.now() / 1000);
    if (exp < currentTime) {
      //alert("Session Expired");
      localStorage.removeItem("token");
      localStorage.removeItem("accesstoken");
      localStorage.removeItem("email");
      localStorage.removeItem("emailId");
      localStorage.removeItem("name");
      localStorage.removeItem("username");
      localStorage.removeItem("id");
      localStorage.removeItem("userId");
      localStorage.removeItem("isVerified");
      localStorage.removeItem("roleId");
      localStorage.removeItem("loggedIn");
      localStorage.removeItem("reffralCode");

      localStorage.removeItem("dealerId");
      localStorage.removeItem("dealerFirstName");
      localStorage.removeItem("dealerLastName");
      localStorage.removeItem("dealerEmailId");
      localStorage.removeItem("emailId");
      localStorage.removeItem("dealerSignup");
      localStorage.removeItem("accesstoken");
      localStorage.removeItem("username");
      localStorage.removeItem("dealerUsername");
      localStorage.removeItem("dealerPassword");
      localStorage.removeItem("dealerAccesstoken");
      localStorage.removeItem("verifiedCount");
      localStorage.removeItem("name");
      navigate("/login");
      return;
    }
    setLoader(true);

    try {
      const response = await OrderService.getOrder(startDate, endDate);
      if (response.status === 200) {
        const list = response.data;
        console.log("list", list);

        setData([]);
        setRows([]);
        if (list != null && list.length > 0) {
          let tempList = [];
          list.forEach((order) => {
            console.log(
              "order.creditCheck.lockMsisdn ",
              order.creditCheck.lockMsisdn
            );
            tempList.push({
              createdDate:
                order.createdDate &&
                order.createdDate
                  .toString()
                  .substring(0, 19)
                  .replace("T", " ") + " GMT",
              orderNumber: order.orderNumber,
              amount: "£" + order.amount,
              orderStatusDescription: order.orderStatus.orderStatusDescription,
              orderTypeDescription: order.orderType.orderTypeDescription,
              username: order.creditCheck.customer.name,
              email: order.creditCheck.customer.email,
              tariffName: order.creditCheck.plan.tariffName,
              planData:
                order.creditCheck.plan.data === -1
                  ? "Unlimited"
                  : `${order.creditCheck.plan.data} GB`,
              noOfMinutes:
                order.creditCheck.plan.noOfMinutes === -1
                  ? "Unlimited"
                  : order.creditCheck.plan.noOfMinutes,
              noOfText:
                order.creditCheck.plan.noOfText === -1
                  ? "Unlimited"
                  : order.creditCheck.plan.noOfText,
              contractTerm: order.creditCheck.plan.contractTerm + " Months",
              networkId: order.creditCheck.plan.network.networkId,
              networkName: order.creditCheck.plan.network.networkName,
              lockMsisdn:
                order.creditCheck.lockMsisdn &&
                "0" + order.creditCheck.lockMsisdn,
              iccid: order.creditCheck.iccid,
              mobileNoSelectionId:
                order.creditCheck.mobileNumberSelection.mobileNoSelectionId ===
                1
                  ? "New Number"
                  : order.creditCheck.mobileNumberSelection
                      .mobileNoSelectionId === 2 ||
                    order.creditCheck.mobileNumberSelection
                      .mobileNoSelectionId === 3
                  ? "Port In"
                  : "",
              signupCommission: "£" + order.signupCommission,
              orderCommission: "£" + order.orderCommission,
              totalCommission: "£" + order.totalCommission,
            });
          });

          setData(tempList);
          setRows(tempList);
        }
      }
    } catch (error) {
      if (error.message === "Network Error") {
        setErrors((prev) => ({
          ...prev,
          orderFetch: "Network error occurred. Please try again later.",
        }));
        setLoader(false);
      } else {
        switch (error.response ? error.response.status : "") {
          case 401:
            setErrors((prev) => ({
              ...prev,
              orderFetch: error.response.data.failedReason,
            }));
            break;
          case 403:
            setErrors((prev) => ({
              ...prev,
              orderFetch: error.response.data.failedReason,
            }));
            break;
          case 500:
            // console.log("Error: ", error.response.data.failedReason);
            setErrors((prev) => ({
              ...prev,
              orderFetch:
                "Internal server error occurred. Please try again later.",
            }));
            break;
          case 404:
            setErrors((prev) => ({
              ...prev,
              orderFetch: error.response.data.failedReason,
            }));
            break;
          case 406:
            setErrors((prev) => ({
              ...prev,
              orderFetch: error.response.data.failedReason,
            }));
            break;
          case 412:
            setErrors((prev) => ({
              ...prev,
              orderFetch: error.response.data.failedReason,
            }));
            break;
          default:
            setErrors((prev) => ({
              ...prev,
              orderFetch: "Unknown error occurred. Please try again later.",
            }));
            break;
        }
        setLoader(false);
      }
    } finally {
      setLoader(false);
    }
  };

  //functions
  // Handle Search
  // const handleSearch = (e) => {
  //   let searchValue = false;

  //   const newRows = rows.filter((row) => {
  //     console.log("row==",row);
  //     if (userRoleId != null && userRoleId === "ROLE_DEALER") {
  //       if (
  //         row.createdDate.toString().includes(e.target.value.toString()) ||
  //         row.orderNumber
  //           .toString()
  //           .toLowerCase()
  //           .includes(e.target.value.toString().toLowerCase()) ||
  //         row.amount.toString().includes(e.target.value.toString()) ||
  //         row.orderStatusDescription
  //           .toString()
  //           .toLowerCase()
  //           .includes(e.target.value.toString().toLowerCase()) ||
  //         row.orderTypeDescription
  //           .toString()
  //           .toLowerCase()
  //           .includes(e.target.value.toString().toLowerCase()) ||
  //         row.username
  //           .toString()
  //           .toLowerCase()
  //           .includes(e.target.value.toString().toLowerCase()) ||
  //         row.email
  //           .toString()
  //           .toLowerCase()
  //           .includes(e.target.value.toString().toLowerCase()) ||
  //         row.tariffName
  //           .toString()
  //           .toLowerCase()
  //           .includes(e.target.value.toString().toLowerCase()) ||
  //         row.planData.toString().includes(e.target.value.toString()) ||
  //         row.noOfMinutes
  //           .toString()
  //           .toLowerCase()
  //           .includes(e.target.value.toString().toLowerCase()) ||
  //         row.noOfText
  //           .toString()
  //           .toLowerCase()
  //           .includes(e.target.value.toString().toLowerCase()) ||
  //         row.contractTerm
  //           .toString()
  //           .toLowerCase()
  //           .includes(e.target.value.toString().toLowerCase()) ||
  //         row.networkName
  //           .toString()
  //           .toLowerCase()
  //           .includes(e.target.value.toString().toLowerCase()) ||
  //         row.lockMsisdn?.toString().includes(e.target.value.toString()) ||
  //         row.iccid?.toString().includes(e.target.value.toString()) ||
  //         row.startDate?.toString.includes(e.target.value.toString())||
  //         row.endDate?.toString.includes(e.target.value.toString())
  //       ) {
  //         searchValue = true;
  //       } else {
  //         searchValue = false;
  //       }
  //     } else {
  //       if (
  //         row.createdDate.toString().includes(e.target.value.toString()) ||
  //         row.orderNumber
  //           .toString()
  //           .toLowerCase()
  //           .includes(e.target.value.toString().toLowerCase()) ||
  //         row.amount.toString().includes(e.target.value.toString()) ||
  //         row.orderStatusDescription
  //           .toString()
  //           .toLowerCase()
  //           .includes(e.target.value.toString().toLowerCase()) ||
  //         row.orderTypeDescription
  //           .toString()
  //           .toLowerCase()
  //           .includes(e.target.value.toString().toLowerCase()) ||
  //         row.tariffName
  //           .toString()
  //           .toLowerCase()
  //           .includes(e.target.value.toString().toLowerCase()) ||
  //         row.planData.toString().includes(e.target.value.toString()) ||
  //         row.noOfMinutes
  //           .toString()
  //           .toLowerCase()
  //           .includes(e.target.value.toString().toLowerCase()) ||
  //         row.noOfText
  //           .toString()
  //           .toLowerCase()
  //           .includes(e.target.value.toString().toLowerCase()) ||
  //         row.contractTerm
  //           .toString()
  //           .toLowerCase()
  //           .includes(e.target.value.toString().toLowerCase()) ||
  //         row.networkName
  //           .toString()
  //           .toLowerCase()
  //           .includes(e.target.value.toString().toLowerCase()) ||
  //         row.lockMsisdn?.toString().includes(e.target.value.toString())||
  //         row.startDate?.toString.includes(e.target.value.toString())||
  //         row.endDate?.toString.includes(e.target.value.toString())
  //       ) {
  //         searchValue = true;
  //       } else {
  //         searchValue = false;
  //       }
  //     }
  //     return searchValue;
  //   });

  //   setData(newRows);
  // };

  const handleSearch = (e) => {
    const searchTerm = e.target.value.toString().toLowerCase();
  
    const newRows = rows.filter((row) => {
      if (userRoleId === "ROLE_DEALER") {
        // For DEALERS, check all fields
        const dealerFields = [
          row.createdDate?.toString() || '',
          row.orderNumber?.toString().toLowerCase() || '',
          row.amount?.toString() || '',
          row.orderStatusDescription?.toString().toLowerCase() || '',
          row.orderTypeDescription?.toString().toLowerCase() || '',
          row.username?.toString().toLowerCase() || '',
          row.email?.toString().toLowerCase() || '',
          row.tariffName?.toString().toLowerCase() || '',
          row.planData?.toString() || '',
          row.noOfMinutes?.toString().toLowerCase() || '',
          row.noOfText?.toString().toLowerCase() || '',
          row.contractTerm?.toString().toLowerCase() || '',
          row.networkName?.toString().toLowerCase() || '',
          row.lockMsisdn?.toString() || '',
          row.iccid?.toString() || ''
        ];
  
        // If any of the dealer fields match the search term, return true
        return dealerFields.some((field) => field.includes(searchTerm));
      } else {
        // For NON-DEALERS, only check the specified fields
        return (
          row.createdDate?.toString().includes(e.target.value.toString()) ||
          row.orderNumber?.toString().toLowerCase().includes(searchTerm) ||
          row.amount?.toString().includes(e.target.value.toString()) ||
          row.orderStatusDescription?.toString().toLowerCase().includes(searchTerm) ||
          row.orderTypeDescription?.toString().toLowerCase().includes(searchTerm) ||
          row.tariffName?.toString().toLowerCase().includes(searchTerm) ||
          row.planData?.toString().includes(e.target.value.toString()) ||
          row.noOfMinutes?.toString().toLowerCase().includes(searchTerm) ||
          row.noOfText?.toString().toLowerCase().includes(searchTerm) ||
          row.contractTerm?.toString().toLowerCase().includes(searchTerm) ||
          row.networkName?.toString().toLowerCase().includes(searchTerm) ||
          row.lockMsisdn?.toString().includes(e.target.value.toString()) 
        );
      }
    });
  
    setData(newRows);
  };

  const exportToExcel = (data) => {
    // console.log("data==>",data);
    let transformedData = [];
    const filteredData = data.filter((order) => {
      if (userRoleId !== null && userRoleId === "ROLE_DEALER") {
        transformedData = data.map((order) => ({
          "Order Date": order.createdDate || "",
          "Order Number": order.orderNumber || "",
          "Order Amount":
            order.orderTypeDescription === "Contract Sim"
              ? order.amount !== undefined
                ? order.amount
                : ""
              : "-",
          "Order Status": order.orderStatusDescription || "",
          "Order Type":
            order.orderTypeDescription !== "Contract Sim"
              ? "PAYM"
              : order.orderTypeDescription || "",
          "Customer Name": order.username || "",
          "Email ID": order.email || "",
          "Plan Name":
            order.orderTypeDescription !== "Contract Sim"
              ? "Pay As You Go Sim"
              : order.tariffName || "",
          Data:
            order.orderTypeDescription === "Contract Sim"
              ? order.planData || ""
              : "-",
          Minutes:
            order.orderTypeDescription === "Contract Sim"
              ? order.noOfMinutes || ""
              : "-",
          Text:
            order.orderTypeDescription === "Contract Sim"
              ? order.noOfText || ""
              : "-",
          Validity:
            order.orderTypeDescription === "Contract Sim"
              ? order.contractTerm || ""
              : "-",
          Network: order.networkName || "",
          "Mobile Number":
            order.orderTypeDescription === "Contract Sim"
              ? order.lockMsisdn || ""
              : "-",
          ICCID:
            order.orderTypeDescription === "Contract Sim"
              ? order.iccid || ""
              : "-",
          "Port In?":
            order.orderTypeDescription === "Contract Sim"
              ? order.mobileNoSelectionId || ""
              : "New Number",
          "Signup Commission": order.signupCommission || "",
          "Order Commission": order.orderCommission || "",
          "Total Commission": order.totalCommission || "",
        }));
      } else {
        transformedData = data.map((order) => ({
          "Order Date": order.createdDate || "",
          "Order Number": order.orderNumber || "",
          "Order Amount":
            order.orderTypeDescription === "Contract Sim"
              ? order.amount !== undefined
                ? order.amount
                : ""
              : "-",
          // "Order Amount": order.amount !== undefined ? order.amount : "",
          "Order Status": order.orderStatusDescription || "",
          "Order Type":
            order.orderTypeDescription !== "Contract Sim"
              ? "PAYM"
              : order.orderTypeDescription || "",
          "Customer Name": order.username || "",
          "Email ID": order.email || "",
          "Plan Name":
            order.orderTypeDescription !== "Contract Sim"
              ? "Pay As You Go Sim"
              : order.tariffName || "",
          Data:
            order.orderTypeDescription === "Contract Sim"
              ? order.planData || ""
              : "-",
          Minutes:
            order.orderTypeDescription === "Contract Sim"
              ? order.noOfMinutes || ""
              : "-",
          Text:
            order.orderTypeDescription === "Contract Sim"
              ? order.noOfText || ""
              : "-",
          Validity:
            order.orderTypeDescription === "Contract Sim"
              ? order.contractTerm || ""
              : "-",
          Network: order.networkName || "",
          "Mobile Number":
            order.orderTypeDescription === "Contract Sim"
              ? order.lockMsisdn || ""
              : "-",
          ICCID:
            order.orderTypeDescription === "Contract Sim"
              ? order.iccid || ""
              : "-",
          "Port In?":
            order.orderTypeDescription === "Contract Sim"
              ? order.mobileNoSelectionId || ""
              : "New Number",
          // "Signup Commission": order.signupCommission || "",
          // "Order Commission": order.orderCommission || "",
          // "Total Commission": order.totalCommission || "",
        }));
      }
    });
    // Create a new worksheet
    const worksheet = XLSX.utils.json_to_sheet(transformedData);

    // Create a new workbook
    const workbook = {
      Sheets: { Orders: worksheet },
      SheetNames: ["Orders"],
    };

    // Convert workbook to a buffer
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    // Get the current date
    const date = new Date();
    const formattedDate = `${date.getFullYear()}-${String(
      date.getMonth() + 1
    ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;
    const filename = `Order Data ${formattedDate}.xlsx`;

    // Save the file with the generated filename
    const dataBlob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    FileSaver.saveAs(dataBlob, filename);
  };

  return (
    <>
      <script src=""></script>
      <Navbar />
      {errors?.orderFetch && (
        <p className="error-text text-center">{errors.orderFetch}</p>
      )}
      <div className="mt-3">
        <main className="my-order-table mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="flex flex-wrap items-center justify-between border-b border-gray-200 py-6">
            {userRoleId === "ROLE_DEALER" ? (
              <h1 className="text-2xl font-semibold tracking-tight mb-3">
                Customer Order Data
              </h1>
            ) : (
              <h1 className="text-2xl font-semibold tracking-tight mb-3">
                My Plans
              </h1>
            )}
            <div className="flex flex-wrap gap-y-4 items-center space-x-2">
              <input
                className="rounded-lg bg-transparent border custom-border-50 focus:border-2 focus:custom-border-50 placeholder:text-teal-800/50 placeholder:text-sm w-44 ml-2 h-10"
                type="date"
                name="startDate"
                value={startDate.split("/").join("-")}
                onChange={handleDateChange}
              />
              <input
                className="rounded-lg bg-transparent border custom-border-50 focus:border-2 focus:custom-border-50 placeholder:text-teal-800/50 placeholder:text-sm w-44 ml-2 h-10"
                type="date"
                name="endDate"
                value={endDate.split("/").join("-")}
                onChange={handleDateChange}
              />
              <button
                onClick={() => fetchData(startDate, endDate)}
                className="cta-one px-2 py-2 w-1/15 rounded-lg"
              >
                Go
              </button>
              {rows?.length > 0 ? (
                <button
                  onClick={() => exportToExcel(rows,userRoleId)} // Call the export function with rows data
                  className="cta-one px-3 py-2 w-1/10 rounded-lg"
                >
                  Export to Excel
                </button>
              ) : (
                ""
              )}

              <input
                type="search"
                className="rounded-lg bg-transparent border custom-border-50 focus:border-2 focus:custom-border-50 placeholder:text-teal-800/50 placeholder:text-sm w-44 ml-2 h-10"
                placeholder="Search"
                onChange={handleSearch}
              />
            </div>
          </div>
          {userRoleId === "ROLE_DEALER" ? (
            rows.length > 0 && data.length > 0 ? (
              <DataTable
                columns={columns}
                data={data}
                fixedHeader
                pagination
                //customStyles={tableCustomStyles}
                //theme="solarized"
              />
            ) : (
              <h1 className="text-center">No Plan Found</h1>
            )
          ) : (
            <div className="lg:col-span-3 relative">
              {/* <div className="absolute bottom-[50px] z-50 w-full h-[200px] bg-grad"></div> */}
              <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-4">
                {rows?.length > 0 &&
                  rows?.map((item, index) => (
                    <OrderCard
                      key={index}
                      data={item}
                      brandIndex={item.networkId}
                    />
                  ))}
              </div>
              {rows?.length == 0 && (
                <div className="w-full min-h-screen flex flex-col items-center mt-24">
                  <LuSearchX className="text-5xl mb-2" />
                  <p>Sorry, we couldn't find any results</p>
                </div>
              )}
            </div>
          )}
        </main>
      </div>
      {loader && <Loader />}
    </>
  );
};

export default MyOrder;
